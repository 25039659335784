import React from 'react';

import {
  Wrap,
  LinkBtn,
} from './styled';

const Footer = () => {
  return (
    <Wrap>
      <LinkBtn to={'https://tradingbattles.com/terms-and-conditions/'}>Terms and Conditions</LinkBtn>
      <LinkBtn to="mailto:contact@tradingbattles.com">Contact</LinkBtn>
    </Wrap>
  );
};

export default Footer;
