import styled, { css } from 'styles/styled';
// eslint-disable-next-line import/no-cycle
import { InputProps } from './Input';

const labelActiveStyles = css`
  top: -16px;
  color: #EFEFEF;
  font-size: 14px;
  @media (max-width: 720px) {
    top: -13px;
    font-size: 10px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 16px;
  transition: ${({ theme }) => theme.ui.transition('all')};
  color: ${({ theme }) => theme.palette.grayMedium};
  font-size: ${({ theme }) => theme.fontSize.base};
  @media (max-width: 720px) {
    top: 13px;
    font-size: 14px;
  }
`;

export const StyledInput = styled.input.attrs((props) => ({
  type: props.type,
}))<InputProps & { password: boolean }>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 50px;
  background-color: #292B2E;
  border-radius: 14px;
  padding: 0 20px;
  width: 100%;
  font-size: 16px;
  vertical-align: text-bottom;
  color: ${({ theme }) => theme.palette.light};
  transition: ${({ theme }) => theme.ui.transition('border-bottom')};

  @media (max-width: 720px) {
    height: 38px;
    border-radius: 8px;
    font-size: 14px;
    padding: 0 15px;
  }

  &:focus {
    outline: none;
  }

  &:autofill + ${Label}, :not(:placeholder-shown) + ${Label} {
    ${labelActiveStyles};
  }

  &:focus + ${Label}, :not(:placeholder-shown) + ${Label} {
    ${labelActiveStyles};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: transparent;
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 26px;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  @media (max-width: 720px) {
    top: 20px;
  }
`;

export const IconClickableWrapper = styled.div`
  width: 20px;
  height: 14px;
  overflow: hidden;
  @media (max-width: 720px) {
    width: 15px;
    height: 15px;
  }
  svg {
    width: 20px;
    height: 14px;
    @media (max-width: 720px) {
      width: 15px;
      height: 15px;
    }
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 20px;
  padding: 5px 0;
`;

export const ErrorText = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.palette.error};
`;
