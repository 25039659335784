import React, {useEffect, useState} from 'react';
import { ButtonFilled } from 'components/ui/Button';
import { useTournament } from 'context/TournamentContext';

import {
  Wrap,
  TitleModal,
  TextModal,
  ButtonModal,
  WrapCenter,
  TextModalSmall,
} from './styled';

const ModalSmallInfo = () => {
  const { tournamentDetails } = useTournament();
  const tournamentId = tournamentDetails?.data?.id;
  const [displayPopUp, setDisplayPopUp] = useState(true);
  const [isStepInfo, setStepInfo] = useState('');

  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab2 = () => { setActiveTab("tab2"); };



  const closePopUp = () => {
    localStorage.setItem('FeeInfoPopup', 'true')
    setDisplayPopUp(false);
  };

  useEffect(() => {
    let returningUser = localStorage.getItem("FeeInfoPopup");
    if (tournamentId === 126) {
      setActiveTab("tab2");
      setDisplayPopUp(true);
    } else {
      setDisplayPopUp(!returningUser);
    }
  }, [tournamentId]);

  useEffect(() => {
    if (tournamentId === 126 ) {
      setStepInfo('1/2')
    }
  }, [tournamentDetails?.data?.id, tournamentId]);

  return (
    <>
      {displayPopUp && (
        <Wrap>
          <div>
            <WrapCenter>

              <TitleModal>Tournament information {isStepInfo ?<b>{activeTab === "tab2" ? "2/2" : "1/2"}</b>: null}</TitleModal>

              {activeTab === "tab1" ?
                <>
                  <TextModal><b>0.1%</b> transaction fee and <b>0.1%</b> spread is added to each transaction</TextModal>
                  {isStepInfo ?
                    <ButtonModal>
                      <ButtonFilled type="button" onClick={handleTab2}>Next</ButtonFilled>
                    </ButtonModal>
                  :
                    <ButtonModal>
                      <ButtonFilled type="button" onClick={closePopUp}>OK</ButtonFilled>
                    </ButtonModal>
                  }
                </>
              :
                <>
                  <TextModalSmall>
                    The results will be announced on Wednesday at <b>2:30 PM</b> at the <b>Future Blockchain</b> Stage in <b>Hall 9</b>.<br /><br />
                    We kindly ask all of the <b>TOP 50</b> winners to visit Trading Battles booth (H9-D6) after 12pm on Wednesday to be verified to collect the reward"
                  </TextModalSmall>
                  <ButtonModal>
                    <ButtonFilled type="button" onClick={closePopUp}>OK</ButtonFilled>
                  </ButtonModal>
                </>
              }

            </WrapCenter>
          </div>
        </Wrap>
      )}
    </>
  );
};

export default ModalSmallInfo;
