import styled from 'styles/styled';
import img from '../../../assets/base_line.svg';
import imgSlide from '../../../assets/base_line_slide.svg';

export const InputRow = styled.div`
  margin-bottom: 14px;
  position: relative;
  @media (max-width: 720px) {
    margin-bottom: 5px;
  }
`;

export const InputText = styled.input`
  background-color: transparent;
  outline: none;
  height: 30px;
  padding: 0 45px 0 60px;
  width: 100%;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  color: #fefefe;
  line-height: 24px;
  @media (max-width: 720px) {
    height: 30px;
    border-radius: 8px;
    margin-bottom: 5px;
    padding-top: 6px;
    font-size: 12px;
  }
  &::placeholder {
    color: #fefefe;
  }
`;

export const InputRange = styled.input`
width: 100%;
-webkit-appearance: none;
appearance: none;
height: 22px;
display: inline-block ;
background-color: #15191E;
background-image: url(${img});
background-size: cover;
background-position: left center;
background-repeat: no-repeat;
outline: none;
@media (max-width: 720px) {
  margin-top: 5px;
}
&:disabled {
  filter: saturate(0.5);
}

&[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 22px;
  width: 22px;
  background-color: #EFEFEF;
  border: 4px solid #15191E;
  border-radius: 50%;
  transition: .1s ease-in-out;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin-top: -8px;
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      margin: 0;
  }}
}

&[type=range]:focus {
    outline: none;
}

&[type=range]:focus::-webkit-slider-runnable-track {
    background: transparent;
}

&[type=range]::-webkit-slider-runnable-track {
    background: transparent;
    border: none;
    border-radius: 3px;
}
`;

export const LineRange = styled.div`
  height: 10px;
  position: absolute;
  top: 7px;
  background-image: url(${imgSlide});
  background-size: 395px;
  background-position: 5px center;
  background-repeat: no-repeat;
  pointer-events: none;
  @media (max-width: 1000px) {
    background-size: 328px;
  }
  @media (max-width: 720px) {
    top: 6px;
    background-size: 447px;
  }
  @media (max-width: 420px) {
    background-size: 307px;
    top: 5px;
  }
  &.true {
    opacity: 0;
  }
`;

export const Percentages = styled.h5`
  position: absolute;
  top: -21px;
  right: 0;
  opacity: 0;
  transition: all 100ms linear;
  &.active {
    opacity: 1;
  }
  span {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 14px;
    width: 34px;
    text-align: center;
    background-color: #EFEFEF;
    color: #292B2E;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    border-radius: 4px;
    @media (max-width: 720px) {
      font-size: 8px;
      height: 10px;
      line-height: 10px;
      width: 25px;
    }
  }
`;

export const Info = styled.div`
  max-width: 430px;
  width: 100%;
  h6 {
    color: #A0A0A0;
    margin-bottom: 10px;
    @media (max-width: 720px) {
      margin-bottom: 45px;
    }
    span {
      color: #EFEFEF;
    }
  }
`;

export const Row = styled.div`
  background-color: #292B2E;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 30px;
  margin-bottom: 8px;
  @media (max-width: 720px) {
    height: 30px;
    border-radius: 8px;
    margin-bottom: 5px;
  }
  &.DarkInfo {
    h6 {
      color: #989898;
    }
  }
  &.RowInput {
    position: relative;
    padding: 0 8px;
    h6 {
      top: 0;
      position: absolute;
      &.TextSpecial {
        left: 13px;
        padding: 3px 0 0 0;
        pointer-events: none;
      }
      &.AmountSpecial {
        right: 13px;
        padding: 3px 0 0 0;
        pointer-events: none;
      }
    }
  }
`;

export const Text = styled.h6`
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const Amount = styled.h6`
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const LoadButton = styled.div`
  button.buttonLoad {
    :disabled {
      color: #aaa;
      font-size: 12px;
    }
  }
`;

export const LoadWrap = styled.div`
  display: block;
  width: 15px;
  height: 15px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 0.6px 0.5px 0 0.6px #aaa;
  margin-right: 5px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
