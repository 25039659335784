import React, { ReactNode } from 'react';
import { useTournament } from 'context/TournamentContext';

import BgDesktopJoin from '../../../assets/PageDetail/fullbodyJoin.png';
import BgMobileJoin from '../../../assets/PageDetail/bgJoinMobile.png';
import BgDesktopJoinBL from '../../../assets/BlockChainLife2024/DesktopBLIntro.png';
import BgMobileJoinBL from '../../../assets/BlockChainLife2024/joinBl_mobile.png';

import {
  Wrap,
  TopRow,
  ContentRow,
  RowWrap,
} from './styled';

interface Props {
  children: ReactNode;
}

const JoinWrapper = ({ children }: Props) => {
  const { tournamentDetails } = useTournament();
  const tournamentId = tournamentDetails?.data?.id;

  return (
    <>
      <Wrap>
        <RowWrap>
          <TopRow>
            {(tournamentId === 122 ) ? (
              <>
                <img src={BgDesktopJoinBL} className="DesktopImg" alt="Join img" />
                <img src={BgMobileJoinBL} className="MobileImg BlMobileImg" alt="Join img" />
              </>
            ) :
              <>
                <img src={BgDesktopJoin} className="DesktopImg" alt="Join img" />
                <img src={BgMobileJoin} className="MobileImg" alt="Join img" />
              </>
            }
          </TopRow>
          <ContentRow>
            {children}
          </ContentRow>
        </RowWrap>
      </Wrap>
    </>
  );
};

export default JoinWrapper;
