import styled from 'styles/styled';
import iconClose from '../../../assets/icons/close_icon.svg';

export const Wrap = styled.div`
  max-width: calc(100% - 200px);
  margin: 0 auto;
  padding: 15px 0;
  @media (max-width: 1380px) {
    max-width: calc(100% - 60px);
  }
  @media (max-width: 1280px) {
    max-width: calc(100% - 25px);
  }
`;

export const Table = styled.table`
  width: 100%;
  min-height: 190px;
  tr {
    display: flex;
  }
  tbody {
    height: 160px;
    min-height: 130px;
    overflow: auto;
    display: block;
    @media (min-height: 1600px) {
      height: calc(30vh - 123px);
      height: calc(30svh - 123px);
    }
  }
  &.scrollBox {
    height: 190px;
    min-height: 70px;
    overflow: auto;
    display: block;
    @media (min-height: 1600px) {
      height: calc(30vh - 93px);
      height: calc(30svh - 93px);
    }
  }
  td, th {
    &.small_col_x1 {
      max-width: 60px;
    }
    &.small_col_x2 {
      max-width: 70px;
    }
  }
`;

export const Td = styled.td`
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #EFEFEF;
  text-align: center;
  padding: 0 2px 7px 2px;
  flex: 1;
  @media (max-width: 1480px) {
    font-size: 10px;
  }
  @media (max-width: 1280px) {
    font-size: 9px;
  }
  &:first-child {
    width: 95px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
  &.detail_state {
    color: #DBB230;
  }
  span {
    width: 66px;
    border-radius: 7px;
    line-height: initial;
    font-weight: 700;
    padding: 2px;
    display: inline-block;
    text-align: center;
    color: rgb(105, 105, 105);
    border: 1px solid rgb(105, 105, 105);
    &.up_color {
      color: #52D381
    }
  }
`;

export const Th = styled.th`
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 1px;
  font-size: 10px;
  color: #696969;
  padding: 0 3px 12px 3px;
  text-align: center;
  position: sticky;
  text-transform: uppercase;
  flex: 1;
  top: 0;
  background-color: #15191E;
  @media (max-width: 1480px) {
    font-size: 10px;
  }
  @media (max-width: 1280px) {
    font-size: 9px;
  }
  &:first-child {
    width: 95px;
    max-width: 120px;
  }
`;

export const Tab = styled.div`
  margin-bottom: 20px;
`;

export const Button = styled.button`
  font-size: 12px;
  margin: 0 10px;
  line-height: 20px;
  font-weight: 700;
  color: #696969;
  appearance: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  transition: all 100ms linear;
  &:hover {
    opacity: 0.8;
  }
  &.active {
    color: #EFEFEF;
    &:after {
      content: " ";
      position: absolute;
      bottom: -2px;
      width: 20px;
      left: 0;
      height: 2px;
      border-radius: 3px;
      background-color: #EFEFEF;
    }
  }
`;







export const ClosePopup = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  right: -20px;
  top: -20px;
  background-size: 30px;
  cursor: pointer;
  background-image: url(${iconClose});
  @media (max-width: 920px) {
    right: -13px;
  }
  @media (max-width: 720px) {
    right: -13px;
    background-size: 25px;
  }
`;

export const WrapModal = styled.div`
  background-color: #15191E;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  min-width: 390px;
  div {
    min-height: inherit !important;
  }
  h4 {
    padding-top: 10px;
    font-size: 18px;
    color: #EFEFEF;
    font-weight: 500;
    margin-bottom: 8px;
    text-align: center;
  }
  button {
    width: 50%;
    min-height: 36px;
  }
`;

export const WrapTPSL = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 80%);
  z-index: 100;
  height: 100vh;
  color: #EFEFEF;
  display: flex;
  justify-content: center;
  align-items: center;
  h3, p {
    display: none;
  }
  button {
    width: 100%;
  }
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 65px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const RowInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
`;

export const InfoMargin = styled.span`
  color: #090B0E;
  background-color: #52D381;
  font-size: 10px;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  &.down_color {
    background-color: #FF4B4A;
  }
  &.up_color {
    background-color: #52D381;
  }
`;

export const Leverage = styled.span`
  color: #090B0E;
  font-size: 10px;
  background-color: #DBB230;
  padding: 3px 5px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
`;

export const CurrencyInfo = styled.h4`
  font-size: 11px;
  font-weight: bold;
  color: #efefef;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ColWrap = styled.div`
  display: flex;
  padding: 9px 6px 9px 0;
  justify-content: space-between;
  min-height: 75px;
  display: flex;
  align-items: center;
  &.editColWrap {
    justify-content: center;
  }
`;

export const SmallBox = styled.div`
  border: 1px dashed #EFEFEF;
  border-radius: 4px;
  background-color: #292B2E;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: initial;
  padding: 5px 8px 5px 4px;
  min-width: 150px;
  cursor: pointer;
  h5 {
    width: initial;
    line-height: 11px;
    margin-left: 10px;
    &.down_color {
      color: #FF4B4A;
    }
    &.up_color {
      color: #52D381;
    }
    span {
      width: initial;
      margin: 0 8px 0 0;
    }
  }
`;

export const SmallText = styled.h4`
  font-size: 13px;
  font-weight: 700;
  color: #EFEFEF;
  margin-bottom: 2px;
`;

export const Text = styled.h5`
  font-weight: 400;
  line-height: 12px;
  font-size: 8px;
  color: #EFEFEF;
  display: flex;
  width: 100%;
  flex: 1;
  span {
    font-weight: 300;
    color: #696969;
    text-align: right;
    width: 50%;
    margin-right: 5px;
  }
  &.empty_state {
    max-width: 50px;
    min-width: 20px;
    margin: 0;
  }
`;

export const EditBoxWrap = styled.div`
  p {
    font-size: 12px;
    color: #efefef;
    line-height: 15px;
    text-align: center;
  }
  button {
    width: 50%;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  justify-content: space-between;
  .button {
    width: calc(50% - 4px);
  }
`;

export const ListWrap = styled.div`
  border-radius: 14px;
  border: 1px solid #696969;
  background-color: #000;
  min-height: 20px;
  overflow: hidden;
  margin-bottom: 8px;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  button {
    background-color: #292B2E;
    margin: 0 6px;
    color: #EFEFEF;
    min-height: 35px;
    height: 35px;
    font-size: 13px;
    min-width: 100px;
    &:hover {
      background-color: #292B2E;
    }
  }
  p.up_color {
    color: #52D381;
  }
`;

export const ListWrapCol = styled.div`
  display: flex;
  align-items: center;
  .box_right {
    width: inherit;
    margin-left: 20px;
    span {
      line-height: initial;
    }
  }
`;

export const ListText = styled.div`
  display: flex;
  min-width: calc(100% - 105px);
  align-items: center;
  p {
    &.down_color {
      color: #FF4B4A;
    }
    &.up_color {
      color: #52D381;
    }
  }
  h5 {
    display: flex;
    flex-direction: column;
    width: inherit;
    margin: 0 10px;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    min-width: 11%;
    @media (max-width: 1400px) {
      font-size: 8px;
      line-height: inherit;
    }
    span {
      width: inherit;
      text-align: center;
      line-height: initial;
      margin: 0 0 2px 0;
      font-weight: 600;
    }
  }
  p {
    line-height: 12px;
  }
  .info_text {
    margin-left: 20px;
    font-size: 13px;
    color: #EFEFEF;
  }
`;

export const ListRightButton = styled.div`
  display: flex;
  align-items: center;
`;

export const ColLeft = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 275px);
`;

export const WrapIcon = styled.div`
  width: 26px;
  height: 26px;
  margin: 6px;
  border-radius: 150px;
  overflow: hidden;
`;

export const Icon = styled.div<{ $src?: string; }>`
  width: 26px;
  height: 26px;
  overflow: hidden;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.$src});
`;
