import React, { useState, useEffect } from 'react';
import { useTournament } from 'context/TournamentContext';
import { LANDING } from 'constants/routes';
import { WalletDataProvider } from 'context/WalletDataContext';
import { ReactComponent as BackIcon } from 'assets/Icon/angle-left-solid.svg';
import { MobileView } from 'react-device-detect';

import IconSvg from 'components/ui/IconSvg';
import FullPageLoad from 'components/ui/FullPageLoad';

import MobileBottomSection from 'components/StatusFlow/MobileBottomSection';
import BottomSection from 'components/StatusFlow/BottomSection';

import StatusWrapper from 'components/layout/StatusWrapper';
import TournamentRanking from 'components/game/TournamentRanking';

import JoinWrapper from 'components/layout/JoinWrapper';
import EndWrapper from 'components/layout/EndWrapper';

import EndPage from 'components/End/EndPage';
import JoinPage from 'components/Join/JoinPage';

import {
  Wrapper,
  ColLeft,
  ColRight,
  Box,
  RowTop,
  BtnBack,
} from './styled';

const StatusTournament = () => {
  const { tournamentDetails, tournamentStatus } = useTournament();
  const [isloadPage, setLoadPage] = useState<Promise<boolean | void> | boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => setLoadPage(false), 1500)
    return () => clearTimeout(timeout);
  }, [])
  if (isloadPage) {
    return <FullPageLoad/>;
  };

  return (
    <StatusWrapper>
      <WalletDataProvider tournamentDetails={tournamentDetails}>
        <Wrapper>
          <BtnBack to={LANDING}>
            <IconSvg width={8} Icon={BackIcon} />BACK
          </BtnBack>

          <RowTop>
            <ColLeft>
              <Box>
                {(tournamentStatus === 'FINISHED') ? (
                  <EndWrapper>
                    <EndPage tournamentDetails={tournamentDetails} />
                  </EndWrapper>
                ) :
                  <JoinWrapper>
                    <JoinPage tournamentDetails={tournamentDetails} />
                  </JoinWrapper>
                }
              </Box>
            </ColLeft>
            <ColRight>
              <Box>
                <TournamentRanking />
              </Box>
            </ColRight>
          </RowTop>

          <BottomSection tournamentDetails={tournamentDetails} />
        </Wrapper>

        <MobileView>
          <MobileBottomSection />
        </MobileView>
      </WalletDataProvider>
    </StatusWrapper>
  );
}
export default StatusTournament;
