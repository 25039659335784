import React, { useState } from 'react';

import {
  Wrap,
  Td,
  Th,
  Table,
  Tab,
  Button,
} from './styled';

const TradeHistoryEmpty = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };

  return (
    <Wrap>
      <Tab>
        <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Trade History</Button>
        <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Asset Performance</Button>
      </Tab>

      {activeTab === "tab1" ?

        <>
          <Table>
            <thead>
              <tr>
                <Th>Trading pair</Th>
                <Th>Amount</Th>
                <Th>Price (USDT)</Th>
                <Th>Value (USDT)</Th>
                <Th>Type</Th>
                <Th>Time (UTC)</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>-</Td>
                <Td>-</Td>
                <Td>-</Td>
                <Td>-</Td>
                <Td>-</Td>
                <Td>-</Td>
              </tr>
            </tbody>
          </Table>
        </>

        :

        <>
          <Table>
            <thead>
              <tr>
                <Th>Asset</Th>
                <Th>Amount</Th>
                <Th>AVG Price (USDT)</Th>
                <Th>P/L USDT</Th>
                <Th>P/L%</Th>
                <Th></Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>-</Td>
                <Td>-</Td>
                <Td>-</Td>
                <Td>-</Td>
                <Td>-</Td>
                <Td></Td>
              </tr>
            </tbody>
          </Table>
        </>

      }
    </Wrap>
  );
};
export default TradeHistoryEmpty;
