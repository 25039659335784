import styled from 'styles/styled';

export const LineText = styled.h4`
  text-align: center;
  font-size: 50px;
  background-color: #52D381;
  border-radius: 14px;
  color: #15191E;
  padding: 1px 0;
  font-weight: 900;
  min-width: 120px;
  padding: 20px 0;
  margin: 0 10px;
  @media (max-width: 720px) {
    min-width: 60px;
    font-size: 30px;
    margin: 0 5px;
    padding: 10px 0;
  }
  @media (min-width: 2300px) and (max-width: 2320px) {
    @media (min-height: 1330px) and (max-height: 1350px) {
      font-size: 90px;
      width: 180px;
      height: 180px;
      flex-direction: column;
      justify-content: center;
      display: flex;
    }
  }
  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: 720px) {
      font-size: 10px;
    }
    @media (min-width: 2300px) and (max-width: 2320px) {
      @media (min-height: 1330px) and (max-height: 1350px) {
        font-size: 22px;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: 720px) {
    margin: 10px 0 60px 0;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 40px;
  background-color: #52D381;
  border-radius: 14px;
  color: #15191E;
  padding: 1px 0;
  font-weight: 900;
  min-width: 120px;
  padding: 20px 60px;
  display: table;
  margin: 20px auto 0 auto;
  @media (max-width: 720px) {
    font-size: 18px;
    margin: 20px auto 40px auto;
  }
`;
