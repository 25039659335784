import styled from 'styles/styled';

export const Wrap = styled.div`
  max-width: calc(100% - 200px);
  margin: 0 auto;
  padding: 15px 0;
  @media (max-width: 1380px) {
    max-width: calc(100% - 60px);
  }
  @media (max-width: 1280px) {
    max-width: calc(100% - 25px);
  }
`;

export const Table = styled.table`
  width: 100%;
  min-height: 130px;
  tr {
    display: flex;
  }
  tbody {
    height: 100px;
    min-height: 70px;
    overflow: auto;
    display: block;
    @media (min-height: 1600px) {
      height: calc(30vh - 123px);
      height: calc(30svh - 123px);
    }
  }
`;

export const Td = styled.td`
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #EFEFEF;
  text-align: center;
  padding: 0 3px 3px 3px;
  flex: 1;
  @media (max-width: 1480px) {
    font-size: 10px;
  }
  @media (max-width: 1280px) {
    font-size: 9px;
  }
  &:first-child {
    width: 95px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
`;

export const Th = styled.th`
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 1px;
  font-size: 10px;
  color: #696969;
  padding: 0 3px 12px 3px;
  text-align: center;
  position: sticky;
  text-transform: uppercase;
  flex: 1;
  top: 0;
  background-color: #15191E;
  @media (max-width: 1480px) {
    font-size: 10px;
  }
  @media (max-width: 1280px) {
    font-size: 9px;
  }
  &:first-child {
    width: 95px;
    max-width: 120px;
  }
`;

export const Tab = styled.div`
  margin-bottom: 20px;
`;

export const Button = styled.button`
  font-size: 12px;
  margin: 0 10px;
  line-height: 20px;
  font-weight: 700;
  color: #696969;
  appearance: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  transition: all 100ms linear;
  &:hover {
    opacity: 0.8;
  }
  &.active {
    color: #EFEFEF;
    &:after {
      content: " ";
      position: absolute;
      bottom: -2px;
      width: 20px;
      left: 0;
      height: 2px;
      border-radius: 3px;
      background-color: #EFEFEF;
    }
  }
`;
