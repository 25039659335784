import React, { useContext, useEffect, useState } from 'react';
import SocketContext from 'utils/hooks/useSocket';
import { useWalletData } from 'context/WalletDataContext';
import { getTournamentMarginAssets } from 'api/tournamentService';

import {
  Row,
  NameAssets,
  ContentAssets,
  IconWrap,
  Name,
  ListAssets,
  Status,
  Icon,
  TextInfo,
  LoadWrap,
} from './styled';

const MyAssets = ({ onIconClick, tournamentID, tournamentType }) => {
  const { walletData } = useWalletData();
  const { allCoinsValues } = useContext(SocketContext);
  const [tradingPair, setTradingPair] = useState([]);
  const [loading, setLoading] = useState(true);
  const currency24hData = allCoinsValues;

  useEffect(() => {
    const fetchAssets = async () => {
      setLoading(true);
      if (tournamentType === "Margin") {
        try {
          const marginAssets = await getTournamentMarginAssets(tournamentID);
          const formattedAssets = marginAssets.map(asset => ({
            short_name: asset,
            name: `${asset}/USDT`
          }));
          setTradingPair(formattedAssets);
        } catch (error) {
          console.error('Error fetching margin assets:', error);
        }
      } else {
        const tradingPairWithAmount = walletData.filter(obj => obj.amount > 0 && obj.ticker.short_name !== 'USDT');
        const tradingPair = tradingPairWithAmount.map(obj => obj.ticker);
        tradingPair.forEach(pair => {
          pair.name = `${pair.short_name}/USDT`;
        });
        setTradingPair(tradingPair);
      }
      setLoading(false);
    };

    fetchAssets();
  }, [tournamentID, tournamentType, walletData]);

  return (
    <>
      <Row>
        <NameAssets>Cryptocurrencies</NameAssets>
        <ContentAssets>
          {loading ? (
            <LoadWrap />
          ) : (
            <ul>
              {tradingPair.map((pair, id) => (
                <ListAssets key={id} onClick={() => onIconClick(pair)}>
                  <IconWrap>
                    <Icon className={pair.short_name.toUpperCase()} $src={`../icons/tradingPair/use/${pair.short_name.toUpperCase()}.svg`} />
                  </IconWrap>
                  <Status className={currency24hData[pair.short_name.toLowerCase()]?.change >= 0 ? "up_state" : "down_state"} />
                  <Name>{`${pair.short_name}`}</Name>
                </ListAssets>
              ))}
            </ul>
          )}

          {!loading && tradingPair.length === 0 && (
            <TextInfo>
              Your assets will appear here once you make the first transaction
            </TextInfo>
          )}
        </ContentAssets>
      </Row>
    </>
  );
};

export default MyAssets;
