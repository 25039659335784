import styled from 'styles/styled';

export const WrapperModal = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgb(0 0 0 / 90%);
  top: 0;
  z-index: 500;
  display: flex;
  align-items: center;
`;

export const Wrap = styled.div`
  width: 1440px;
  margin: 0 auto;
  max-width: 90%;
  @media (max-width: 720px) {
    width: 460px;
    max-width: 90%;
    margin: 0 auto 0 auto;
  }
`;

export const Content = styled.div`
  background-color: rgb(21, 25, 30);
  padding: 24px 0;
  position: relative;
  border-radius: 8px;
  @media (max-width: 720px) {
    padding: 20px 0 15px 0;
  }
`;

export const WrapContent = styled.div`
  width: 1200px;
  margin: 0 auto;
  max-width: 95%;
`;

export const Title = styled.h4`
  text-align: center;
  line-height: 38px;
  font-weight: 700;
  font-size: 18px;
  color: #EFEFEF;
`;
