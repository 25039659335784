import styled from 'styles/styled';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';

export const Wrap = styled.div`
  padding: 12px 0;
`;

export const LinkBtn = styled(StyledLink)`
  font-size: 12px;
  font-weight: 400;
  margin: 0 4px;
  color: #EFEFEF;
`;
