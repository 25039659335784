import styled from 'styles/styled';

export const LineText = styled.h4`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #000;
  min-height: 22px;
  background-color: #EFEFEF;
  width: 100%;
  line-height: 22px;
`;

export const LoadFullPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  height: 100vh;
  justify-content: center;
`;

export const LoadWrap = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 1px 1px 0 1px #52d382;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
