export const HOME = '/';

// UNAUTHENTICATED
export const FORGOT_PASSWORD = `${HOME}forgot-password`;
export const FORGOT_PASSWORD_SEND_EMAIL = `${HOME}forgot-password-send-email`;
export const PASSWORD_RESET = `${HOME}password-reset`;
export const RESET_PASSWORD_SUCCESS = `${HOME}reset-password-success`;
export const REGISTER = `${HOME}register`;

//
export const GAME = `${HOME}game/:id`;
export const STATUSTOURNAMENT = `${HOME}join/:id`;
export const RESULTS = `${HOME}results/:id`;

export const LANDING = process.env.REACT_APP_LANDING_PAGE || '';
export const LANDING_TOURNAMENTS = process.env.REACT_APP_LANDING_PAGE_TOURNAMENTS || '';

export const LOGOUT =`${HOME}logout`;
