import styled from 'styles/styled';

export const Row = styled.div`
  margin-bottom: 15px;
`;

export const Icon = styled.div<{ $src?: string; }>`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.$src});
  @media (min-width: 900px) {
    width: 60px;
    height: 60px;
  }
  &.POL {
    background-size: 80%;
    background-color: #eef0f4;
    background-repeat: no-repeat;
  }
`;

export const NameAssets = styled.h4`
  font-size: 12px;
  color: #EFEFEF;
  font-weight: 500;
  line-height: 9px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 150px;
  background-color: #454545;
  height: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ContentAssets = styled.div`
  background-color: #15191E;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 4px 8px;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ListAssets = styled.li`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 4px;
  cursor: pointer;
  @media (min-width: 900px) {
    width: 60px;
    height: 60px;
  }
`;

export const Name = styled.h5`
  font-size: 8px;
  font-weight: 500;
  border: 0.5px solid #EFEFEF;
  width: 100%;
  position: absolute;
  bottom: 2px;
  color: #EFEFEF;
  background-color: #292B2E;
  border-radius: 2px;
  text-align: center;
  padding: 1px 1px 0 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (min-width: 900px) {
    font-size: 10px;
    padding: 2px 0;
  }
`;

export const IconWrap = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100px;
  @media (min-width: 900px) {
    width: 60px;
    height: 60px;
  }
`;

export const Status = styled.div`
  width: 11px;
  height: 11px;
  border: 1px solid #EFEFEF;
  border-radius: 100px;
  overflow: hidden;
  position: absolute;
  background-color: #000;
  right: 0;
  top: 0;
  &.up_state {
    background-color: #52D381;
  }
  &.down_state {
    background-color: #FF4B4A;
  }
`;
