import styled from 'styles/styled';
import bgGraph from '../../../assets/PageDetail/bgGraphCut.png';
import imgWin from '../../../assets/imgWin.svg';
import imgShadow from '../../../assets/boxJoinShadow.png';

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(180deg, #15191E 0%, rgba(0, 0, 0, 0.78125) 15.45%, #15191E 100%);
  border-radius: 10px;
  overflow: hidden;
`;

export const RowWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${bgGraph});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  max-height: calc(100vh - 65px - 8px);
  max-height: calc(100svh - 65px - 8px);
  @media (min-width: 720px) {
    min-height: 600px;
  }
  @media (max-width: 720px) {
    min-height: 500px;
  }
  &:after {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    background-image: url(${imgWin});
    content: " ";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 210px;
    max-height: 200px;
    height: 20vh;
    @media (max-width: 1020px) {
      height: 150px;
    }
    @media (max-width: 720px) {
      right: -25px;
      height: 100px;
    }
  }
`;

export const TopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 100%;
  overflow: hidden;
  margin-top: -100px;
  @media (min-height: 780px) {
    margin-top: -200px;
  }
  img {
    max-height: 100%;
    width: 90%;
    margin: 0 auto;
    object-fit: cover;
    &.DesktopImg {
      display: none;
      @media (min-height: 780px) {
        width: 110%;
        overflow: hidden;
        position: relative;
        left: 50%;
        right: 0px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      @media (min-width: 720px) {
        display: block;
      }
    }
    &.MobileImg {
      display: none;
      padding-top: 20px;
      @media (max-width: 720px) {
        display: block;
      }
      &.BlMobileImg {
        padding-top: 0;
      }
    }
  }
`;

export const ContentRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 520px;
  margin: 0 auto;
  background-image: url(${imgShadow});
  padding: 25px 0 15px 0;
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: center top;
  @media (max-width: 1020px) {
    background-size: 200px;
  }
`;
