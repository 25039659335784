import React, { ReactNode, useState } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import { useTournament } from 'context/TournamentContext';

import {
  ModalWrap,
  ModalContent,
  ModalArticle,
  Title,
  BullImg,
  FireworksImg,
  LoadWrap,
} from './styled';

interface Props {
  children: ReactNode;
}

const handleReloadPage = () => {
  window.location.reload();
};

const Modal = ({ children }: Props) => {
  const [loadPageHide, setloadPageHide] = useState(false);
  const { tournamentStatus } = useTournament();
  const [isDisabledCalculation, setDisabledCalculation] = useState(true);

  setTimeout(() => {
    setloadPageHide(true);
  }, 10);

  setTimeout(() => {
    setDisabledCalculation(false);
  }, 5000);

  return (
    <>
      {loadPageHide ?
        <>
          {tournamentStatus === 'IN_PROGRESS' &&
            <ModalWrap className="modalEndWrap">
              <ModalContent>
                <ModalArticle>
                  <BullImg/>
                  <FireworksImg/>

                  <div>
                    {children}
                  </div>

                  {isDisabledCalculation ?
                    <ButtonFilled disabled={isDisabledCalculation} className="buttonLoad" type="button"><LoadWrap></LoadWrap></ButtonFilled>
                  :
                    <ButtonFilled onClick={handleReloadPage} type="button">Continue</ButtonFilled>
                  }

                </ModalArticle>
              </ModalContent>
            </ModalWrap>
          }
        </>
      : null }
    </>
  );
};

export default Modal;
