import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthenticationWrapper from 'components/layout/AuthenticationWrapper';
import { REGISTER, GAME, LANDING, FORGOT_PASSWORD } from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';
import { useUser } from 'api/userService';
import Cookies from 'js-cookie';
import { getCookieDomainFromUrl } from 'utils';

import {
  Title,
  Text,
  IntroHead,
  StyledInput,
  BottomWrapper,
  ForgotPasswordLink,
} from './styled';

const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { login } = useUser();
  const intendedTournamentId = localStorage.getItem('intendToJoinTournament') ?? Cookies.get('intendToJoinTournament');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await login(email, password, token => {
        if (intendedTournamentId) {
          localStorage.removeItem('intendToJoinTournament');
          Cookies.remove('intendToJoinTournament', { path: '/', domain });
          // Redirect to the specific tournament page
          window.location.href = `${GAME.replace(':id', intendedTournamentId)}`;

        } else {
          setTimeout(() => {
            window.location.href = LANDING;
          }, 250);
        }
      });
    } catch (error: unknown) {
      console.error('Login error:', error);
      if (typeof error === 'object' && error !== null && 'error' in error && typeof (error as { error: { message: string } }).error.message === 'string') {
        setError((error as { error: { message: string } }).error.message);
      } else {
        setError('An unexpected error occurred');
      }
    }
  };
  return (
    <AuthenticationWrapper>
      <IntroHead>
        <Title>Welcome back!</Title>
      </IntroHead>
      <form onSubmit={handleSubmit}>

        <StyledInput
          label="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <StyledInput
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          errorText={error}
        />

        <ForgotPasswordLink>
          <Link to={FORGOT_PASSWORD}>Forgot password?</Link>
        </ForgotPasswordLink>

        <BottomWrapper>
          <ButtonFilled type="submit" onClick={handleSubmit}>Log in</ButtonFilled>
          <Text>New here? <Link to={REGISTER}>Register now</Link></Text>
        </BottomWrapper>

      </form>
    </AuthenticationWrapper>
  );
};

export default Login;
