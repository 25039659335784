import styled from 'styles/styled';

export const Wrap = styled.div`
  max-width: 470px;
  line-height: 20px;
  margin: 0 auto;
  @media (max-width: 720px) {
    width: 80%;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #696969;
  @media (max-width: 720px) {
    font-size: 10px;
    line-height: 12px;
  }
  a {
    color: #52D381;
  }
`;
