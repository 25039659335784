import styled from 'styles/styled';
import iconClose from './../../../assets/icons/close_icon.svg';

export const Button = styled.div`
  position: fixed;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  background-color: #292B2E;
  width: 160px;
  text-align: center;
  bottom: 60px;
  cursor: pointer;
  display: none;
  z-index: 40;
  transition: all 100ms linear;
  border-radius: 0 0 8px 8px;
  @media (max-width: 1000px) {
    display: block;
    bottom: 34px;
    padding: 5px 0;
    font-size: 14px;
  }
  @media (max-width: 720px) {
    bottom: 18px;
    padding: 5px 0;
    font-size: 12px;
  }
  &:after {
    content: " ";
    width: 100%;
    height: 15px;
    background-color: transparent;
    position: absolute;
    bottom: -11px;
    left: 0;
  }
  &.BtnRanking {
    right: 0;
    transform: rotate(90deg);
    transform-origin: top right;
    &.isActiveRanking {
      right: 60%;
      transition: all 300ms ease-in;
      transition-delay: 20ms;
      color: #52D381;
      @media (max-width: 720px) {
        right: 75%;
      }
    }
  }
  &.BtnTransaction {
    left: 0;
    transform: rotate(-90deg);
    transform-origin: top left;
    &.isActiveTransaction {
      left: 60%;
      transition: all 300ms ease-in;
      transition-delay: 20ms;
      color: #52D381;
      @media (max-width: 720px) {
        left: 75%;
      }
    }
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 0;
  z-index: 90;
  width: 40px;
  height: 30px;
  border-bottom-left-radius: 14px;
  background-color: #000;
  transition: all 100ms linear;
  background-image: url(${iconClose});
  background-repeat: no-repeat;
  background-position: center;
  display: none;
  cursor: pointer;
  @media (max-width: 1000px) {
    display: table;
  }
  @media (max-width: 720px) {
    width: 35px;
    height: 25px;
    background-size: 11px;
  }
`;

export const TradeHistoryAnime = styled.div`
  display: none;
  overflow: hidden;
  @media (max-width: 1000px) {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 80;
    left: -100%;
    width: 60%;
    margin-bottom: 10px;
    height: 260px;
    max-height: 260px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #15191E;
    transition: all 300ms linear;
  }
  @media (max-width: 720px) {
    width: 75%;
    height: 238px;
    min-height: 238px;
    margin-bottom: 8px;
  }
  .isClose {
    left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 12px;
  }
  &.isActiveHistory {
    left: 0;
  }
`;

export const TournamentRankingAnime = styled.div`
  display: none;
  overflow: hidden;
  @media (max-width: 1000px) {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 80;
    right: -100%;
    width: 60%;
    margin-bottom: 10px;
    min-height: 260px;
    max-height: 260px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: #15191E;
    transition: all 300ms linear;
  }
  @media (max-width: 720px) {
    width: 75%;
    height: 238px;
    min-height: 238px;
    margin-bottom: 8px;
  }
  .isClose {
    right: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 12px;
  }
  &.isActiveRanking {
    right: 0;
  }
`;

export const BgAnime = styled.div`
  display: none;
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height: 269px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  @media (max-width: 1000px) {
    &.isActiveSecond,
    &.isActive {
      display: block;
    }
  }
  @media (max-width: 720px) {
    height: 249px;
  }
`;

export const Wrap = styled.div`
  z-index: 120;
  position: relative;
    .isActive,
    .isActiveSecond {
      @media (max-width: 720px) {
        height: 280px;
      }
    }
    .endBgAnime {
      div {
        height: 100%;
      }
    }
    div {
      color: #EFEFEF;
    }
  }
`;

export const WrapList = styled.div`
  padding: 30px 0 20px 0;
  height: 238px;
`;
