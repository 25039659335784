import styled from 'styles/styled';
import Input from 'components/ui/Input';

export const IntroHead = styled.div`
  margin-bottom: 25px;
  @media (max-width: 720px) {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.light};
  @media (max-width: 720px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.light};
  line-height: 24px;
  min-height: 24px;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 20px;
    min-height: 20px;
  }
  a {
    color: ${({ theme }) => theme.palette.primary};
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 5px;
`;

export const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  button {
    width: calc(50% - 10px);
  }
  a {
    width: calc(50% - 10px);
    button {
      width: 100%;
      color: #EFEFEF;
      border: 1px solid #52D381;
      background-color: transparent;
      &:hover {
        opacity: 0.9;
        background-color: #090B0E;
      }
    }
  }
`;

export const StepInfo = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  @media (max-width: 720px) {
    margin: 10px 0;
  }
`;

export const Step = styled.span`
  width: 80px;
  background-color: #292B2E;
  height: 6px;
  border-radius: 4px;
  margin: 0 15px;
  @media (max-width: 720px) {
    margin: 0 10px;
    width: 60px;
    height: 5px;
  }
  &.is_active {
    background-color: #52D381;
  }
`;
