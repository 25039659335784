import React, { useState } from 'react';
import * as routes from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from 'utils';
import { useWalletData } from 'context/WalletDataContext';
import { useSocket } from 'utils/hooks/useSocket';
import Cookies from 'js-cookie';
import { useTournament } from 'context/TournamentContext';
import { ButtonFilled } from 'components/ui/Button';
import { useUser } from 'api/userService';
import { getCookieDomainFromUrl } from 'utils';

import {
  Row,
  RowBlock,
  IconAvatar,
  Text,
  TextLine,
} from './styled';

const UserState = (props: { tournamentDetails: any; }) => {
  const { tournamentStatus, joinedStatus } = useTournament();
  const { tournamentDetails } = props;
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const userId = localStorage.getItem('userId') ?? Cookies.get('userId');
  const { totalMoneyString, walletData } = useWalletData();
  const { rank, tournamentRanking } = useSocket();
  const initialMoney = tournamentDetails?.data?.attributes?.amount_start || 1;
  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;

  const { isLoggedIn } = useUser();
  const tournamentId = tournamentDetails?.data?.id;
  const [isload, setLoad] = useState(false);
  const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);
  const navigate = useNavigate();

  let totalMoney = 0;
  if (tournamentDetails?.data?.attributes?.status === 'finished') {
    walletData.forEach((wallet) => {
      if (wallet?.ticker?.short_name === 'USDT') {
        totalMoney = wallet.amount;
      }
    });
  } else if (tournamentDetails?.data?.attributes?.status === 'new') {
    totalMoney = initialMoney;
  } else if (tournamentType === 'Margin') {
    const userRanking = tournamentRanking.find(([user]) => user === username);
    totalMoney = userRanking ? parseFloat(userRanking[1]) : 0;
  } else {
    totalMoney = totalMoneyString;
  }

  let percentageChange = ((totalMoney - initialMoney) / initialMoney) * 100;

  if (totalMoney === 0) {
    percentageChange = 0;
  }

  const parseTotalMoney = formatCurrency(totalMoney);
  let parsePercentageChange = (percentageChange.toFixed(2));

  const handleLogInClick = async () => {
    try {
      setLoad(!isload);
      if (!isLoggedIn) {
        localStorage.setItem('intendToJoinTournament', tournamentId);
        Cookies.set('intendToJoinTournament', tournamentId, { expires: 7,secure: true, sameSite: 'none', domain });
        navigate(routes.HOME);
        return;
      }

      const newPath = `${routes.GAME.replace(':id', tournamentId)}`;
      window.location.href = newPath;

    } catch (error) {
      if (error.error.status === 409 && error.error.name === 'ConflictError') {
        window.location.href = `${routes.GAME.replace(':id', tournamentId)}`;
      } else {
        console.error('Error joining tournament:', error);
      }
    }
  };

  const handleRegisterClick = async () => {
    try {
      setLoad(!isload);
      if (!isLoggedIn) {
        localStorage.setItem('intendToJoinTournament', tournamentId);
        Cookies.set('intendToJoinTournament', tournamentId, { expires: 7,secure: true, sameSite: 'none', domain });
        navigate(routes.REGISTER);
        return;
      }

      const newPath = `${routes.GAME.replace(':id', tournamentId)}`;
      window.location.href = newPath;

    } catch (error) {
      if (error.error.status === 409 && error.error.name === 'ConflictError') {
        window.location.href = `${routes.GAME.replace(':id', tournamentId)}`;
      } else {
        console.error('Error joining tournament:', error);
      }
    }
  };

  return (
    <Row>
      {userId ? (
        <>
          <IconAvatar />
          <RowBlock>
            {joinedStatus && (tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START' || tournamentStatus === 'FINISHED')  ? (
              <>
                <Text>Rank {rank}</Text>
                <Text className="nickName">{username}</Text>
                <Text>{parseTotalMoney && <>{parseTotalMoney} USDT</>}</Text>
                {(tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'FINISHED') ? (
                  <Text className={`State ${percentageChange >= 0 ? 'up_state' : 'down_state'}`}>
                    {parsePercentageChange && <>{parsePercentageChange}%</>}
                  </Text>
                ) : null}
              </>
            ) :
              <>
                <Text>Rank ---</Text>
                <Text className="nickName">{username}</Text>
              </>
            }
          </RowBlock>
        </>
      ) :
        <>
          <RowBlock>
            <ButtonFilled onClick={handleLogInClick}>
              Log In
            </ButtonFilled>
            <TextLine>Or</TextLine>
            <ButtonFilled onClick={handleRegisterClick}>
              Register
            </ButtonFilled>
          </RowBlock>
        </>
      }
    </Row>
  );
};

export default UserState;
