import React from 'react';
import Cookies from 'js-cookie';

import {
  NickName,
  AvatarIcon,
  AvatarName,
} from './styled';

const Avatar = () => {
  const username = localStorage.getItem('username') ?? Cookies.get('username');

  return (
    <NickName>
      <AvatarIcon></AvatarIcon>
      <AvatarName>
        {username}
      </AvatarName>
    </NickName>
  );
};

export default Avatar;
