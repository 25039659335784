import React, { useEffect, useState, useContext } from 'react';
import { requestTradingData, closeMargin, cancelMargin } from 'api/socketService';
import SocketContext, { useSocket } from 'utils/hooks/useSocket';
import { useWalletData } from 'context/WalletDataContext';
import { useTradeUpdate } from 'context/TradeUpdateContext';
import { ButtonFilled } from 'components/ui/Button';
import SetTPSL from 'components/ui/SetTPSL';

import {
  Wrap,
  Td,
  Th,
  Table,
  Tab,
  Button,
  BoxInfo,
  RowInfo,
  InfoMargin,
  Leverage,
  CurrencyInfo,
  SmallBox,
  SmallText,
  Text,
  EditBoxWrap,
  BoxButton,
  ListWrap,
  ListWrapCol,
  ListText,
  ListRightButton,
  ColLeft,
  WrapIcon,
  Icon,
  WrapTPSL,
  WrapModal,
  ClosePopup,
} from './styled';

type Trade = {
  id: number;
  trading_pair: string;
  coin_amount: number;
  price: number;
  market_value: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  transaction_type: string;
  leverage: number;
  status: string;
  is_settled: boolean;
  used_funds: number;
  liquidation_price: number;
  description: string;
  limit?: number;
  take_profit?: number;
  stop_loss?: number;
  profit?: number;
  profit_percentage?: number;
};

const TradeHistoryMargin = (props: { tournamentDetails: any; }) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const handleTab3 = () => { setActiveTab("tab3"); };
  const [tradingData, setTradingData] = useState<Trade[]>([]);
  const { tournamentDetails } = useSocket();
  const { walletData, update } = useWalletData();
  const { allCoinsValues, tradingUpdates } = useContext(SocketContext);
  const [isOpenTpSl, setIsOpenTpSl] = useState(false);
  const [takeProfit, setTakeProfit] = useState(undefined);
  const [stopLoss, setStopLoss] = useState(undefined);
  const { triggerTradeUpdate } = useTradeUpdate();
  const [currentTradeId, setCurrentTradeId] = useState(null);
  const [isOpenTransaction, setOpenTransaction] = useState({});

  const handleCancelTransaction = id => {
    setOpenTransaction(showDetails => ({
      ...showDetails,
      [id]: !showDetails[id]
    }));
  };

  const handleAccept = (tradeId) => {
    setIsOpenTpSl(false);
    setTradingData(prevData =>
      prevData.map(trade =>
        trade.id === tradeId
          ? { ...trade, take_profit: Number(takeProfit), stop_loss: Number(stopLoss) }
          : trade
      )
    );
  };

  const handleCancel = () => {
    setIsOpenTpSl(false);
  };

  const updateTrading = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      setTradingData(data);
    } else {
      console.error('Error fetching trading data:');
    }
  };

  const fetchTradingData = () => {
    const tournamentId = tournamentDetails?.data?.id;
    const tradeType = tournamentDetails?.data?.attributes?.trade_type;

    if (tournamentId) {
      requestTradingData(tournamentId, tradeType, updateTrading);
    }
  };

  const [currentWalletData, updateCurrentWalletData] = useState(null)

  if(walletData?.length){
    if(currentWalletData?.length){
      walletData.forEach((element, i) => {
        if(element?.coin_amount !== currentWalletData[i]?.coin_amount){
          updateCurrentWalletData(walletData)
        }
      });
    }else{
      updateCurrentWalletData(walletData)
    }
  }

  useEffect(()=>{
    fetchTradingData();
  },[currentWalletData])

  const filterTradesByStatus = (statuses, isSettled = null) => {
    return tradingData.filter(trade => {
      if (isSettled !== null) {
        return statuses.includes(trade.status) && trade.is_settled === isSettled;
      }
      return statuses.includes(trade.status);
    });
  };

  const calculateProfit = (currentPrice, trade) => {
    const multiplier = trade.transaction_type === 'long' ? 1 : -1;
    const profit = (((currentPrice - trade.break_even_price) / trade.break_even_price) * (trade.used_funds - trade.fee) * trade.leverage * multiplier) - trade.fee;
    const profitPercent = (profit / (trade.used_funds)) * 100;
    return { profit, profitPercent };
  };

  // Update tradingData whenever tradingUpdates changes
  useEffect(() => {
    if (tradingUpdates && Array.isArray(tradingUpdates)) {
      setTradingData(tradingUpdates);
      update(); // Update available funds
    }
  }, [tradingUpdates]);

  return (
    <Wrap>
      <Tab>
        <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Open</Button>
        <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Pending</Button>
        <Button className={activeTab === "tab3" ? "active" : ""} onClick={handleTab3}>History</Button>
      </Tab>

      {isOpenTpSl &&
        <WrapTPSL>
          <WrapModal>
            <ClosePopup onClick={() => setIsOpenTpSl(!isOpenTpSl)} />
            <h4>Edit your Take Profit/Stop Loss</h4>
            <SetTPSL
              takeProfit={takeProfit}
              setTakeProfit={setTakeProfit}
              stopLoss={stopLoss}
              setStopLoss={setStopLoss}
              onAccept={handleAccept}
              onCancel={handleCancel}
              tradeId={currentTradeId}
            />
          </WrapModal>
        </WrapTPSL>
      }

      {activeTab === "tab1" ?
        <Table className="scrollBox">
          {filterTradesByStatus(["filled"], false).map(trade => {
            const tradingPairKey = trade.trading_pair.split('/')[0].toLowerCase();
            const currentPrice = allCoinsValues?.[tradingPairKey]?.last || 0;
            const { profit, profitPercent } = calculateProfit(currentPrice, trade);
            return (
              <ListWrap key={trade.id}>
                <ColLeft>
                  <ListWrapCol>
                    <WrapIcon>
                      <Icon $src={`../icons/tradingPair/use/${trade.trading_pair?.split('/USDT')[0]}.svg`}/>
                    </WrapIcon>
                    <BoxInfo>
                      <RowInfo>
                        <InfoMargin className={trade.transaction_type === "long" ? "up_color" : "down_color"}>
                          {trade.transaction_type === "long" ? "Long" : "Short"}
                        </InfoMargin>
                        <Leverage>X{trade.leverage}</Leverage>
                      </RowInfo>
                      <CurrencyInfo>{trade.trading_pair}</CurrencyInfo>
                    </BoxInfo>
                  </ListWrapCol>

                  <ListText>
                    {isOpenTransaction[trade.id] ?
                      <p className="info_text">Are you sure you want to close this position?</p>
                    :
                      <>
                        <Text><span>Profit</span><p className={profit >= 0 ? "up_color" : "down_color"}>{profit.toFixed(2)} USDT <br />({profitPercent.toFixed(2)}%)</p></Text>
                        <Text><span>Amount</span><p>{trade.coin_amount} {trade.trading_pair.replace('/USDT', '')}</p></Text>
                        <Text><span>Used Funds</span><p>{trade.used_funds.toFixed(2)} USDT</p></Text>
                        <Text><span>Price</span><p>{trade.price.toFixed(2)} USDT</p></Text>
                        <Text><span>Liquidation Price</span><p>{trade.liquidation_price.toFixed(2)} USDT</p></Text>
                        <Text className="empty_state"><span></span><p></p></Text>
                      </>
                    }
                  </ListText>
                </ColLeft>

                <ListRightButton>
                  {isOpenTransaction[trade.id] ?
                    <EditBoxWrap>
                      <BoxButton>
                        <ButtonFilled
                          className="button"
                          type="button"
                          onClick={() => {
                            closeMargin(trade.id, (response) => {
                              if(response.error){
                                console.error(response.error);
                                alert(response.error);
                              }
                              update();
                              triggerTradeUpdate();
                              setOpenTransaction(!isOpenTransaction);
                            });
                          }}
                        >
                          Yes
                        </ButtonFilled>
                        <ButtonFilled
                          className="button"
                          type="button"
                          onClick={() => setOpenTransaction(!isOpenTransaction)}
                        >
                          No
                        </ButtonFilled>
                      </BoxButton>
                    </EditBoxWrap>
                  :
                    <>
                      <SmallBox onClick={() => {
                        setIsOpenTpSl(!isOpenTpSl);
                        setTakeProfit(trade.take_profit ? Number(trade.take_profit) : undefined);
                        setStopLoss(trade.stop_loss ? Number(trade.stop_loss) : undefined);
                        setCurrentTradeId(trade.id);
                      }}>
                        <SmallText>Tap to edit</SmallText>
                        <div>
                          <Text className="up_color"><span>TP</span>{trade.take_profit ?? 'Not set'}</Text>
                          <Text className="down_color"><span>SL</span>{trade.stop_loss ?? 'Not set'}</Text>
                        </div>
                      </SmallBox>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => handleCancelTransaction(trade.id)}
                      >
                        Close position
                      </ButtonFilled>
                    </>
                  }
                </ListRightButton>
              </ListWrap>
            );
          })}
        </Table>
      : null }

      {activeTab === "tab2" ?
        <Table className="scrollBox">
          {filterTradesByStatus(["pending"]).map(trade => (
            <ListWrap key={trade.id}>
              <ColLeft>
                <ListWrapCol>
                  <WrapIcon>
                    <Icon $src={`../icons/tradingPair/use/${trade.trading_pair?.split('/USDT')[0]}.svg`}/>
                  </WrapIcon>
                  <BoxInfo>
                    <RowInfo>
                      <InfoMargin className={trade.transaction_type === "long" ? "up_color" : "down_color"}>
                        {trade.transaction_type === "long" ? "Long" : "Short"}
                      </InfoMargin>
                      <Leverage>X{trade.leverage}</Leverage>
                    </RowInfo>
                    <CurrencyInfo>{trade.trading_pair}</CurrencyInfo>
                  </BoxInfo>
                </ListWrapCol>

                <ListText>
                  {isOpenTransaction[trade.id] ?
                    <p className="info_text">Are you sure you want to cancel this order?</p>
                  :
                    <>
                      <Text><span>Amount</span><p>{trade.coin_amount} {trade.trading_pair.replace('/USDT', '')}</p></Text>
                      <Text><span>Limit</span><p>{trade.limit} USDT</p></Text>
                      <Text><span>Used Funds</span><p>{trade.used_funds} USDT</p></Text>
                      <Text><span></span><p></p></Text>
                      <Text><span></span><p></p></Text>
                      <Text className="empty_state"><span></span><p></p></Text>
                    </>
                  }
                </ListText>
              </ColLeft>

              <ListRightButton>
                {isOpenTransaction[trade.id] ?
                  <EditBoxWrap>
                    <BoxButton>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => {
                          cancelMargin(trade.id, (response) => {
                            if(response.error){
                              console.error(response.error);
                              alert(response.error);
                            }
                            update();
                            triggerTradeUpdate();
                            setOpenTransaction(!isOpenTransaction);
                          });
                        }}
                      >
                        Yes
                      </ButtonFilled>
                      <ButtonFilled
                        className="button"
                        type="button"
                        onClick={() => setOpenTransaction(!isOpenTransaction)}
                      >
                        No
                      </ButtonFilled>
                    </BoxButton>
                  </EditBoxWrap>
                :
                  <>
                    <SmallBox onClick={() => {
                      setIsOpenTpSl(!isOpenTpSl);
                      setTakeProfit(trade.take_profit ? String(trade.take_profit) : '');
                      setStopLoss(trade.stop_loss ? String(trade.stop_loss) : '');
                      setCurrentTradeId(trade.id);
                    }}>
                      <SmallText>Tap to edit</SmallText>
                      <div>
                        <Text className="up_color"><span>TP</span>{trade.take_profit ?? 'Not set'}</Text>
                        <Text className="down_color"><span>SL</span>{trade.stop_loss ?? 'Not set'}</Text>
                      </div>
                    </SmallBox>
                    <ButtonFilled
                      className="button"
                      type="button"
                      onClick={() => handleCancelTransaction(trade.id)}
                    >
                      Cancel order
                    </ButtonFilled>
                  </>
                }
              </ListRightButton>
            </ListWrap>
          ))}
        </Table>
      : null }

      {activeTab === "tab3" ?
        <Table>
          <thead>
            <tr>
              <Th>Trading Pair</Th>
              <Th className="small_col_x2">Type</Th>
              <Th className="small_col_x1">Leverage</Th>
              <Th>Used funds</Th>
              <Th>Amount</Th>
              <Th>Price</Th>
              <Th>Profit</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            {filterTradesByStatus(["filled", "canceled"]).map(trade => {
              return (
                <tr key={trade.id}>
                  <Td>{trade.trading_pair}</Td>
                  <Td className="small_col_x2">{trade.description}</Td>
                  <Td className="detail_state small_col_x1">X{trade.leverage}</Td>
                  <Td>{trade.used_funds} USDT</Td>
                  <Td>{trade.coin_amount} {trade.trading_pair.split('/')[0]}</Td>
                  <Td>{trade.price.toFixed(2)} USDT</Td>
                  <Td className={trade.profit >= 0 ? "up_state" : "down_state"}>{trade.profit !== null ? `${trade.profit} USDT` : ''}</Td>
                  <Td><span className={trade.status === "filled" ? "up_color" : "detail_color"}>{trade.status.charAt(0).toUpperCase() + trade.status.slice(1)}</span></Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      : null }
    </Wrap>
  );
};
export default TradeHistoryMargin;
