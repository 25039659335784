import styled from 'styles/styled';

export const ColLeft = styled.div`
  width: calc(100% - 290px);
  display: flex;
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export const ColRight = styled.div`
  width: 282px;
  display: flex;
  @media (max-width: 1020px) {
    display: none;
  }
  &.asideBox {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  background-color: #15191E;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 8px;
  &.centerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &:first-child {
      height: 100%;
    }
  }
`;

export const RowBottom = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
    @media (max-width: 1020px) {
      display: none;
    }
`;
