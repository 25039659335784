import styled from 'styles/styled';

export const Row = styled.div`
  border: 1px solid #515152;
  border-bottom: none;
  height: 24px;
  display: flex;
`;

export const Button = styled.button`
  font-size: 12px;
  color: #696969;
  line-height: 24px;
  font-weight: 500;
  background-color: transparent;
  margin: 0 3px;
  cursor: pointer;
  &.is_active,
  &:hover {
    color: #FEFEFE;
  }
  &:first-child {
    margin-left: 4px;
  }
`;

export const Text = styled.h5`
  font-size: 12px;
  color: #FEFEFE;
  line-height: 24px;
  font-weight: 500;
  margin: 0 6px 0 10px;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const IconWrap = styled.div`
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 720px) {
    margin: 0 5px;
  }
  svg {
    height: 12px;
  }
`;

export const IconBox = styled.div`
  border-left: 1px solid #515152;
  display: flex;
  padding-left: 10px;
  margin-left: 20px;
  @media (max-width: 720px) {
    padding-left: 5px;
    margin-left: 5px;
  }
`;

export const MoreInfoBox = styled.div`
  position: absolute;
  top: 29px;
  z-index: 20;
  display: none;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  width: 200px;
  padding: 8px 10px;
  border-radius: 12px;
  border: 1px solid #FEFEFE;
  background-color: #15191E;
  @media (max-width: 720px) {
    padding: 5px;
    max-width: 150px;
    border-radius: 8px;
    right: -20px;
    left: inherit;
    transform: translate(0, 0);
  }
  &.isActiveChartType {
    display: block;
  }
  &.isActiveIndicators {
    display: block;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #FEFEFE;
    text-align: center;
    @media (max-width: 720px) {
      font-size: 11px;
    }
  }
`;

export const LineButton = styled.div`
  display: flex;
  justify-content: space-around;
`;
