import React, { useState, useEffect, useContext, useRef } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import { makeTrade } from 'api/socketService';
import { useWalletData } from 'context/WalletDataContext';
import { useTradeUpdate } from 'context/TradeUpdateContext';
import { v4 as uuidv4 } from 'uuid';

import {
  Row,
  Text,
  Amount,
  InputRow,
  Info,
  InputRange,
  InputText,
  LineRange,
  Percentages,
  LoadButton,
  LoadWrap,
} from './styled';

import SocketContext from 'utils/hooks/useSocket';

const SellBox = (props) => {
  const [rangeval, setRangeval] = useState(0);
  const [btcAmount, setBtcAmount] = useState(0);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const { tournamentDetails } = props;
  const { walletData, update } = useWalletData();
  const { triggerTradeUpdate } = useTradeUpdate();
  const [isTrading, setIsTrading] = useState(false);
  const [coinInputValue, setCoinInputValue] = useState('');
  const [usdtInputValue, setUsdtInputValue] = useState('');
  const [inputFocused, setInputFocused] = useState(null);
  const {selectedCoinValue, selectedCoin } = useContext(SocketContext);
  const lastClickTimestamp = useRef(0);


  let shortNameSelectedCoin = selectedCoin?.name?.split('/')[0]
  let priceWallet = walletData.find(wallet => wallet.ticker.short_name === shortNameSelectedCoin);
  let availableCoinValue = priceWallet ? priceWallet.amount : 0;
  let price = selectedCoinValue.last;
  let precision = selectedCoin?.base?.data?.attributes?.precision ? selectedCoin?.base?.data?.attributes?.precision : selectedCoin?.precision;
  if(precision > 8){precision = 8;}
  let disabledBTN = availableCoinValue.toFixed(precision) <= 0;

  const handleBtcInputChange = (value) => {
    setInputFocused('BTC');
    const numericValue = value === '' ? 0 : Number(value);
    setCoinInputValue(value.toFixed(precision)); // Update display value

    // If the entered BTC amount exceeds the available, cap it
    const cappedBtcValue = numericValue > availableCoinValue ? availableCoinValue : numericValue;
    setBtcAmount(cappedBtcValue);
    setUsdtAmount(cappedBtcValue * price);

    // Update the slider's value
    const sliderValue = (cappedBtcValue / availableCoinValue) * 100;
    setRangeval(sliderValue <= 100 ? sliderValue : 100);
  };

  const handleBtcInputFocus = () => {
    setCoinInputValue(''); // Clear BTC input on focus
    setInputFocused(shortNameSelectedCoin);
  };

  const handleUsdtInputFocus = () => {
    setUsdtInputValue(''); // Clear USDT input on focus
    setInputFocused('USDT');
  };

  const handleUsdtInputChange = (value) => {
    setInputFocused('USDT');
    const numericValue = value === '' ? 0 : Number(value);
    setUsdtInputValue(value); // Update display value

    // Calculate the BTC amount based on the entered USDT value, don't exceed available BTC
    const newBtcAmount = Math.min(numericValue / price, availableCoinValue);
    setBtcAmount(newBtcAmount);
    setCoinInputValue(newBtcAmount.toFixed(precision));

    // Update the slider's value
    const sliderValue = (newBtcAmount / availableCoinValue) * 100;
    setRangeval(sliderValue <= 100 ? sliderValue : 100);
  };

  const handleBtcInputBlur = () => {
    setInputFocused(null);
    // If BTC input is not empty, keep the formatted value
    if (coinInputValue === '') {
      setBtcAmount(0); // Ensure the numeric state is zero when input is empty
      setUsdtAmount(0); // Also reset the USDT amount
    }
  };

  const handleUsdtInputBlur = () => {
    setInputFocused(null);
    // If USDT input is not empty, keep the formatted value
    if (usdtInputValue === '') {
      setUsdtAmount(0); // Ensure the numeric state is zero when input is empty
      setBtcAmount(0); // Also reset the BTC amount
    }
  };

  const handleSliderChange = (event) => {
    const value = Number(event.target.value);
    const newBtcAmount = (value / 100) * availableCoinValue;
    const newUsdtAmount = newBtcAmount * price;

    setRangeval(value); // Update the slider value
    setBtcAmount(newBtcAmount); // Update the BTC amount
    setUsdtAmount(newUsdtAmount); // Update the USDT amount
    setCoinInputValue(newBtcAmount.toFixed(precision)); // Update the BTC input display
    setUsdtInputValue(newUsdtAmount.toFixed(2)); // Update the USDT input display
  };

  useEffect(() => {
    const newBtcAmount = (rangeval / 100) * availableCoinValue;
    setBtcAmount(newBtcAmount);
    setUsdtAmount(newBtcAmount * price);
  }, [rangeval, availableCoinValue, price]);

  const handleSellClick = () => {
    if (isTrading) {
      return;
    }
    const now = Date.now();
    setIsTrading(true);

    if (now - lastClickTimestamp.current < 1000) {
      return;
    }

    lastClickTimestamp.current = now;

    const tradePayload = {
      tournament: tournamentDetails?.data?.id,
      tradingPair: selectedCoin?.name,
      ticker: shortNameSelectedCoin,
      type: "sell",
      value: Number(btcAmount.toFixed(20)),
      token: uuidv4(),
    };

    makeTrade(tradePayload, (response) => {
      update();
      triggerTradeUpdate();
      resetValues();
      setIsTrading(false);
    });
  };

  const resetValues = () => {
    setRangeval(0);
    setBtcAmount(0);
    setUsdtAmount(0);
  };
  return (
    <>
      <Info>
        <Text>Available funds<span> {availableCoinValue.toFixed(precision)} {shortNameSelectedCoin}</span></Text>
      </Info>
      <Row className="DarkInfo">
        <Text>Market price</Text>
        <Amount>{price} USDT</Amount>
      </Row>
      <InputRow>
        <LineRange style={{ width: `${rangeval}%` }}>
          <Percentages className={!btcAmount ? "" : "active"}>
            {/* Guard against NaN by checking if rangeval is a number */}
            <span>{!isNaN(rangeval) ? `${rangeval.toFixed(0)}%` : '0%'}</span>
          </Percentages>
        </LineRange>
        <InputRange
          disabled={disabledBTN}
          type="range"
          defaultValue="0"
          value={rangeval}
          min="0"
          max="100"
          onChange={handleSliderChange}
        />
      </InputRow>


      <Row className="RowInput">
        <Text className="TextSpecial">Amount</Text>
        <InputText
          type="number"
          value={inputFocused === 'BTC' ? coinInputValue : btcAmount.toFixed(precision)}
          pattern="\d*(\.\d+)?"
          step="0.0001"
          min="0"
          onFocus={handleBtcInputFocus}
          onBlur={handleBtcInputBlur}
          onChange={(e) => handleBtcInputChange(e.target.value)}
        />
        <Amount className="AmountSpecial">{shortNameSelectedCoin}</Amount>
      </Row>
      <Row className="RowInput">
        <Text className="TextSpecial">Sum</Text>
        <InputText
          type="number"
          value={inputFocused === 'USDT' ? usdtInputValue : usdtAmount.toFixed(2)}
          pattern="\d*(\.\d+)?"
          step="0.01"
          min="0"
          onFocus={handleUsdtInputFocus}
          onBlur={handleUsdtInputBlur}
          onChange={(e) => handleUsdtInputChange(e.target.value)}
        />
        <Amount className="AmountSpecial">USDT</Amount>
      </Row>

      {isTrading ?
        <LoadButton>
          <ButtonFilled className="buttonLoad" disabled={true} type="button"><LoadWrap></LoadWrap> Loading...</ButtonFilled>
        </LoadButton>
      :
        <ButtonFilled
          type="button"
          className="SellDetails"
          disabled={disabledBTN ? true : false}
          onClick={handleSellClick}>
          Sell
        </ButtonFilled>
      }
    </>
  );
};

export default SellBox;
