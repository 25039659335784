import styled from 'styles/styled';
import iconClose from '../../../assets/icons/close_icon.svg';

export const Title = styled.h4`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #EFEFEF;
  text-align: center;
  margin: 0 auto 8px auto;
  @media (max-width: 720px) {
    font-size: 16px;
    width: 280px;
    max-width: 90%;
    margin: 0 auto 5px auto;
  }
  span {
    display: block;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 300;
    color: #EFEFEF;
    @media (max-width: 720px) {
      font-size: 12px;
      margin-top: 3px;

    }
  }
`;

export const Row = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 1020px) {
    padding: 10px 0;
    max-width: 380px;
    margin: 0 auto;
  }
  @media (max-width: 720px) {
    max-width: 280px;
  }
`;

export const RowWrap = styled.div`
  max-width: calc(100% - 150px);
  min-width: 240px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
  button {
    width: 100%;
    min-width: inherit;
    min-height: 40px;
    max-width: 460px;
    @media (max-width: 1020px) {
      max-width: 350px;
      margin: 0 auto;
    }
    @media (max-width: 720px) {
      min-height: 30px;
      border-radius: 6px;
      max-width: 250px;
      height: 30px;
      font-size: 14px;
    }
  }
`;

export const Text = styled.h6`
  font-size: 14px;
  color: #EFEFEF;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
  @media (max-width: 720px) {
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 2px;
  }
`;

export const Amount = styled.h5`
  font-size: 18px;
  color: #52D381;
  font-weight: 700;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

export const StatePpl = styled.h5`
  font-size: 14px;
  color: #efefef;
  line-height: 17px;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

export const Time = styled.div`
  background-color: #EFEFEF;
  border-radius: 8px;
  text-align: center;
  padding: 5px 0;
  margin: 0 auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1020px) {
    max-width: 350px;
  }
  @media (max-width: 720px) {
    height: 30px;
    padding: 0;
    border-radius: 6px;
    max-width: 250px;
    font-size: 14px;
  }
  h4 {
    background-color: transparent;
    font-size: 16px;
    line-height: 34px;
    @media (max-width: 720px) {
      font-size: 14px;
    }
  }
`;

export const Load = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #EFEFEF;
`;

export const LoadWrap = styled.div`
  display: block;
  width: 25px;
  height: 25px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 1px 1px 0 1px #52d382;
  @media (max-width: 720px) {
    width: 18px;
    height: 18px;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  &.button_load {
    box-shadow: 1px 1px 0 1px #000;
  }
`;

export const TextSub = styled.p`
  font-size: 14px;
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.light};
  line-height: 24px;
  min-height: 24px;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 20px;
    min-height: 20px;
  }
  a {
    color: ${({ theme }) => theme.palette.primary};
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ContentCheckbox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  button {
    height: 40px;
    min-height: 40px;
    @media (max-width: 720px) {
      min-height: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
`;

export const RowCheckbox = styled.div`
  margin: 0 30px 0 0;
  @media (max-width: 720px) {
    margin: 0 15px 0 0;
  }
  p {
    line-height: initial;
    color: #EFEFEF;
    a {
      color: #52D381;
      font-weight: 500;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  label {
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 720px) {
      p {
        font-size: 11px;
        width: calc(100% - 35px);
      }
    }
    input {
      display: none;
    }
  }

  .checkbox {
    cursor: pointer;
    position: relative;
    height: 20px;
    width: 20px;
    float: left;
    background-color: transparent;
    border: 1px solid #EFEFEF;
    transition: all 0.15s;
    margin-right: 10px;
    @media (max-width: 720px) {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }

  input:focus-visible ~ .checkbox {
    border: 1px solid #52D381;
  }

  .option:hover input ~ .checkbox {
    border: 1px solid #52D381;
  }

  .checkboxStyle:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 3px;
    width: 0px;
    height: 0px;
    border: solid green;
    border-width: 2px 2px 0 0;
    opacity: 0;
    transform-origin: left top;
    transform: scaleX(-1) rotate(135deg);
    transition: width 0.08s ease 0.1s, height 0.1s ease, border-color 0.3s ease,
    opacity 0.1s ease 0.2s, border-width 0.1s ease;
    @media (max-width: 720px) {
      border-width: 1px 1px 0 0;
      top: 6px;
      left: 2px;
    }
  }

  input:checked ~ .checkboxStyle:after {
    opacity: 1;
    width: 7px;
    height: 11px;
    border-color: #52D381;
    transition: width 0.1s ease, height 0.08s ease 0.1s, border-color 0.3s ease,
    border-width 0.1s ease;
    @media (max-width: 720px) {
      width: 8px;
      height: 8px;
    }
  }

  input:checked ~ .checkboxStyle {
    border-color: #52D381;
  }
`;

export const CloseModal = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: -40px;
  background-repeat: no-repeat;
  background-position: center;
  right: 0;
  cursor: pointer;
  background-image: url(${iconClose});
`;

export const TimeText = styled.p`
  font-size: 20px;
  line-height: 34px;
  color: #000;
  font-weight: 700;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

export const ClosePopup = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  right: -50px;
  top: -50px;
  background-size: 30px;
  cursor: pointer;
  background-image: url(${iconClose});
  @media (max-width: 920px) {
    right: -13px;
  }
  @media (max-width: 720px) {
    right: -13px;
    background-size: 25px;
  }
`;

export const ContentModalSimple = styled.div`
  display: table;
  position: relative;
  margin: 0 auto;
  z-index: 1100;
  max-width: 90%;
  @media (max-width: 720px) {
    width: 100%;
    max-width: 460px;
  }
`;


export const ModalSimple = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 140px;
  right: 0;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1000;
  @media (max-width: 720px) {
    padding: 120px 10px 0 10px;
  }
  img {
    max-width: 100%;
    max-height: calc(100vh - 190px);
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1000;
  cursor: pointer;
  background-color: rgba(0,0,0, 0.7);
`;
