import React, { useState } from 'react';
import { useTournament } from 'context/TournamentContext';
import TradeHistoryMobile from 'components/game/TradeHistoryMobile';
import TradeHistoryMarginMobile from 'components/game/TradeHistoryMarginMobile';
import TournamentRankingList from 'components/game/TournamentRankingList';

import {
  Button,
  CloseIcon,
  TradeHistoryAnime,
  TournamentRankingAnime,
  BgAnime,
  Wrap,
  WrapList,
} from './styled';

const MobileFlowBottom = () => {
  const { tournamentDetails, tournamentStatus } = useTournament();
  const [isActiveRankingContent, setIsActiveRanking] = useState(false);
  const [isActiveHistoryContent, setIsActiveHistory] = useState(false);
  const [isActiveBg, setIsBg] = useState(false);
  const [isActiveBgSecond, setIsBgSecond] = useState(false);
  const [showComponentHistory, setShowComponentHistory] = useState(false);
  const [showComponentRanking, setShowComponentRanking] = useState(false);
  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;
  const result = tournamentStatus === 'FINISHED' ? "endBgAnime" : null;

  const handleClickRanking = event => {
    setShowComponentRanking(true);
    setIsActiveRanking(!isActiveRankingContent);
    setIsActiveHistory(false);
    setIsBg(!isActiveBg);
    setIsBgSecond(false);
  };

  const handleClickHistory = event => {
    setShowComponentHistory(true);
    setIsActiveHistory(!isActiveHistoryContent);
    setIsActiveRanking(false);
    setIsBgSecond(!isActiveBgSecond);
    setIsBg(false);
  };

  const handleClickClose = event => {
    setIsBg(false);
    setIsBgSecond(false);
    setIsActiveRanking(false);
    setIsActiveHistory(false);
    setShowComponentHistory(false);
    setShowComponentRanking(false);
  };

  return (
    <Wrap className={tournamentType}>

      <div className={result}>
        <BgAnime className={isActiveBg ? 'isActive' : ''} />
      </div>
      <Button className={`BtnTransaction ${isActiveHistoryContent ? 'isActiveTransaction' : ''}`} onClick={handleClickHistory}>
        Transactions
      </Button>

      <div className={result}>
        <BgAnime className={isActiveBgSecond ? 'isActiveSecond' : ''} />
      </div>
      <Button className={`BtnRanking ${isActiveRankingContent ? 'isActiveRanking' : ''}`} onClick={handleClickRanking}>
        Ranking
      </Button>

      <TradeHistoryAnime className={isActiveHistoryContent ? 'isActiveHistory' : ''}>
        <CloseIcon className="isClose" onClick={handleClickClose} />
        {showComponentHistory &&
          <>
            {(tournamentType === 'Margin') ? (
              <TradeHistoryMarginMobile />
            ) :
              <TradeHistoryMobile tournamentDetails={tournamentDetails}  />
            }
          </>
        }
      </TradeHistoryAnime>

      <TournamentRankingAnime className={isActiveRankingContent ? 'isActiveRanking' : ''}>
        <CloseIcon className="isClose" onClick={handleClickClose} />
        {showComponentRanking &&
          <WrapList>
            <TournamentRankingList />
          </WrapList>
        }
      </TournamentRankingAnime>
    </Wrap>
  );
};

export default MobileFlowBottom;
