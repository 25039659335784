import apiClient from './apiClient';
import { joinTournament } from './tournamentService';
import Cookies from 'js-cookie';
import { getCookieDomainFromUrl } from '../utils';
const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);


/**
 * Registers a user with the provided username, email, and password.
 *
 * @param {string} username - The username of the user.
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @return {Promise<any>} The response data from the API call.
 */
export const register = async (username, email, password) => {
    try {
      const response = await apiClient.post('/auth/local/register', { username, email, password });
      if(response.status === 200) {
        localStorage.setItem('userId', response.data.user.id);
        localStorage.setItem('jwt', response.data.jwt);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('email', response.data.user.email);

         // Setting cookies
         Cookies.set('userId', response.data.user.id, { expires: 7,secure: true, sameSite: 'strict', domain });
         Cookies.set('jwt', response.data.jwt, { expires: 7,secure: true, sameSite: 'none', domain });
         Cookies.set('username', response.data.user.username, { expires: 7,secure: true, sameSite: 'strict', domain });
         Cookies.set('email', response.data.user.email, { expires: 7,secure: true, sameSite: 'strict', domain });

        // await postAuthActions();
      }
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };

  /**
 * Initiates the password reset process for the user with the provided email.
 *
 * @param {string} email - The email of the user who wants to reset their password.
 * @return {Promise<any>} The response data from the API call.
 */
export const forgotPassword = async (email) => {
    try {
      const response = await apiClient.post('/auth/forgot-password', { email });
      return response.data; // Adjust based on your actual API response
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };

  /**
   * Resets the user's password using the provided code, new password, and password confirmation.
   *
   * @param {string} code - The code received for password reset.
   * @param {string} password - The new password for the user.
   * @param {string} passwordConfirmation - The confirmation of the new password.
   * @return {Promise<any>} The response data from the API call.
   */
  export const resetPassword = async (code, password, passwordConfirmation) => {
    try {
      const response = await apiClient.post('/auth/reset-password', { code, password, passwordConfirmation });
      return response.data; // Adjust based on your actual API response
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };

/**
 * A function that performs post-authentication actions.
 *
 * @return {Promise<void>} This function does not return anything.
 */
export const postAuthActions = async () => {
  let intendToJoinTournament = localStorage.getItem('intendToJoinTournament') ?? Cookies.get('intendToJoinTournament');

  if (intendToJoinTournament) {
    setTimeout(async () => {
      try {
        await joinTournament(intendToJoinTournament);
      } catch (error) {
        console.error('Error joining tournament:', error);
      }
    }, 100);
  }
};


export const logout = () => {
    localStorage.clear();
    Cookies.remove('userId', { path: '/', domain });
    Cookies.remove('jwt', { path: '/', domain});
    Cookies.remove('username', { path: '/', domain });
    Cookies.remove('email', { path: '/', domain });
    Cookies.remove('intendToJoinTournament', { path: '/', domain });
  };

/**
 * Checks the current user's details.
 *
 * @return {Promise<any>} The response data from the API call.
 */
export const checkUserDetails = async () => {
  const response = await apiClient.get('/users/me?populate=tournaments');
  return response.data;
};
