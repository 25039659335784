import React, { useState } from 'react';
import { useTournament } from 'context/TournamentContext';
import TournamentRankingList from 'components/game/TournamentRankingList';

import {
  Button,
  CloseIcon,
  TradeHistoryAnime,
  TournamentRankingAnime,
  BgAnime,
  Wrap,
  WrapList,
} from './styled';

const MobileBottomSection = () => {
  const { tournamentDetails } = useTournament();
  const [isActiveRankingContent, setIsActiveRanking] = useState(false);
  const [isActiveHistoryContent, setIsActiveHistory] = useState(false);
  const [isActiveBg, setIsBg] = useState(false);
  const [isActiveBgSecond, setIsBgSecond] = useState(false);
  const [showComponentRanking, setShowComponentRanking] = useState(false);
  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;

  const handleClickRanking = event => {
    setShowComponentRanking(true);
    setIsActiveRanking(!isActiveRankingContent);
    setIsActiveHistory(false);
    setIsBg(!isActiveBg);
    setIsBgSecond(false);
  };

  const handleClickHistory = event => {
    setIsActiveHistory(!isActiveHistoryContent);
    setIsActiveRanking(false);
    setIsBgSecond(!isActiveBgSecond);
    setIsBg(false);
  };

  const handleClickClose = event => {
    setIsBg(false);
    setIsBgSecond(false);
    setIsActiveRanking(false);
    setIsActiveHistory(false);
    setShowComponentRanking(false);
  };

  return (
    <Wrap className={tournamentType}>
      <BgAnime className={isActiveBg ? 'isActive' : ''} />
      <Button disabled className={`BtnTransaction ${isActiveHistoryContent ? 'isActiveTransaction' : ''}`} onClick={handleClickHistory}>
        Transactions
      </Button>

      <BgAnime className={isActiveBgSecond ? 'isActiveSecond' : ''} />
      <Button className={`BtnRanking ${isActiveRankingContent ? 'isActiveRanking' : ''}`} onClick={handleClickRanking}>
        Ranking
      </Button>

      <TradeHistoryAnime className={isActiveHistoryContent ? 'isActiveHistory' : ''}>
        <CloseIcon className="isClose" onClick={handleClickClose} />
        {/* Empty */}
      </TradeHistoryAnime>

      <TournamentRankingAnime className={isActiveRankingContent ? 'isActiveRanking' : ''}>
        <CloseIcon className="isClose" onClick={handleClickClose} />
        {showComponentRanking &&
          <WrapList>
            <TournamentRankingList />
          </WrapList>
        }
      </TournamentRankingAnime>
    </Wrap>
  );
};

export default MobileBottomSection;
