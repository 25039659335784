import React, { useState } from 'react';

import {
  Wrap,
  Td,
  Th,
  Table,
  Tab,
  Button,
} from './styled';

const TradeHistoryMarginEmpty = () => {
  const [activeTab, setActiveTab] = useState("tab3");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const handleTab3 = () => { setActiveTab("tab3"); };

  return (
    <Wrap>
      <Tab>
        <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Open</Button>
        <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Pending</Button>
        <Button className={activeTab === "tab3" ? "active" : ""} onClick={handleTab3}>History</Button>
      </Tab>

      {activeTab === "tab1" ?
        <Table />
      : null }

      {activeTab === "tab2" ?
        <Table />
      : null }

      {activeTab === "tab3" ?
        <Table>
          <thead>
            <tr>
              <Th>Trading Pair</Th>
              <Th>Type</Th>
              <Th>Leverage</Th>
              <Th>Used funds</Th>
              <Th>Amount</Th>
              <Th>Price</Th>
              <Th>Profit</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
            </tr>
          </tbody>
        </Table>
      : null }
    </Wrap>
  );
};
export default TradeHistoryMarginEmpty;
