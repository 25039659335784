export const formatCurrency = (amount, currency = 'USD') => {
    let formatted = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .format(Number(amount))
    .replace('$', ''); // Removing the dollar sign if you don't need it

    if(formatted > 0 && formatted-1 < 0){
      formatted = Number(formatted).toFixed(3)
    }

    return formatted
  };

  export const formatPrice = (pairName, price) => {
    const pairsWithEightDecimals = [
      'SHIB/USDT',
      'FLOKI/USDT',
      'BONK/USDT',
      'PEPE/USDT',
      'BOME/USDT',
      '1000SATS/USDT'
    ];
  
    const pairsWithFiveDecimals = [
      'DOGE/USDT',
      'PEOPLE/USDT',
      'VET/USDT',
      'MEME/USDT'
    ];

    const pairsWithFourDecimals = [
      'ADA/USDT',
      'ALGO/USDT',
      'ARB/USDT',
      'GRT/USDT',
      'HBAR/USDT',
      'ICP/USDT',
      'IMX/USDT',
      'LDO/USDT',
      'POL/USDT',
      'SEI/USDT',
      'SUI/USDT',
      'TRX/USDT',
      'XLM/USDT',
      'XRP/USDT'
    ];
  
    const pairsWithThreeDecimals = [
      'WIF/USDT',
      'ATOM/USDT',
      'DOT/USDT',
      'FIL/USDT',
      'OP/USDT',
      'RUNE/USDT',
      'STX/USDT',
      'NEAR/USDT',
      'UNI/USDT',
      'RENDER/USDT'
    ];
  
    if (pairsWithEightDecimals.includes(pairName)) {
      return price.toFixed(8);
    }
  
    if (pairsWithFiveDecimals.includes(pairName)) {
      return price.toFixed(5);
    }

    if (pairsWithFourDecimals.includes(pairName)) {
      return price.toFixed(4);
    }
  
    if (pairsWithThreeDecimals.includes(pairName)) {
      return price.toFixed(3);
    }
  
    // Default formatting if needed
    return price.toFixed(2);
  };



export const getCookieDomainFromUrl = (url) => {
  try {
    const urlObj = new URL(url);
    const hostname = urlObj.hostname;
    return hostname.startsWith('www.') ? `.${hostname.slice(4)}` : `.${hostname}`;
  } catch (error) {
    console.error('Invalid URL:', url);
    return '';
  }
};
