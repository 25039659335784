import React, { createContext, useContext, useEffect, useState } from 'react';

import { getTournamentDetails, getCryptoTradingPairList } from 'api/tournamentService';
import { useUser } from 'api/userService';

const TournamentContext = createContext();

export const useTournament = () => {
  return useContext(TournamentContext);
};

const TOURNAMENT_STATUSES = {
  new: 'BEFORE_START',
  in_progress: 'IN_PROGRESS',
  finished: 'FINISHED',
};

export const TournamentProvider = ({ children, tournamentId }) => {
  const [tournamentStatus, setTournamentStatus] = useState(null);
  const [joinedStatus, setJoinedStatus] = useState(null);
  const [tournamentDetails, setTournamentDetails] = useState(null);
  const { isLoggedIn, getUserDetailsWithTournaments } = useUser();
  const [tournamentPairList, setTournamentPairList] = useState([])

  const [myAssets, setMyAssets] = useState(null);

  const isJoinedToTournamentWithId = async (tournamentId) => {
    if (isLoggedIn) {
      try {
        const userDetails = await getUserDetailsWithTournaments();
        return userDetails?.tournaments?.some(tournament => tournament.id === tournamentId);
      } catch (error) {
        console.error(error);
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    const checkTournamentPairList = async () => {
      const tournamentId = tournamentDetails?.data?.id;
      if (tournamentId) {
        try {
          const apiData = await getCryptoTradingPairList(tournamentId);
          setTournamentPairList(apiData);
        } catch (error) {
          console.error("Error fetching tournament pair id:", error);
        }
      }
    };
    checkTournamentPairList()
  }, [tournamentDetails])

  useEffect(() => {
    if (tournamentDetails === null) {
      (async () => {
        try {
          const details = await getTournamentDetails(tournamentId);
          setTournamentDetails(details);
          const backendStatus = details?.data?.attributes?.status;
          const customStatus = TOURNAMENT_STATUSES[backendStatus] || backendStatus;
          setTournamentStatus(customStatus);
        } catch (error) {
          console.error('Failed to fetch tournament details:', error);
        }
      })();
    }
  }, [tournamentId]);

  useEffect(() => {
    (async () => {
      const isJoined = await isJoinedToTournamentWithId(tournamentId);
      setJoinedStatus(isJoined);
    })();
  }, [tournamentId, isJoinedToTournamentWithId]);



  const value = {
    tournamentStatus,
    joinedStatus,
    tournamentDetails,
    isJoinedToTournamentWithId,
    setMyAssets,
    myAssets,
    tournamentPairList,
    tournamentId
  };

  return <TournamentContext.Provider value={value}>{children}</TournamentContext.Provider>;
};


export default TournamentContext;
