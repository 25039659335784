import styled from 'styles/styled';

const ErrorContainer = styled.div`
  padding-top: 40vh;
  text-align: center;
  p {
    font-size: ${({ theme }) => theme.fontSize.titleSmall};
  }
`;

export default ErrorContainer;
