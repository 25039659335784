import React, { useEffect, useState, useContext } from "react";
import SocketContext, { useSocket }  from 'utils/hooks/useSocket';
import { formatCurrency, formatPrice } from "utils";
import { useWalletData } from "context/WalletDataContext";
import moment from "moment";
import { BrowserView, MobileView } from 'react-device-detect';
import { useTournament } from 'context/TournamentContext';
import Cookies from 'js-cookie';

import { TournamentCountdown } from '../TournamentCountdown/TournamentCountdown';
import WrapAsset from "components/Assets/WrapAsset";
import GraphFilter from "components/game/GraphFilter";
import TradingView from "components/game/TradingView";

import {
  Box,
  Wrap,
  Amount,
  Currency,
  BeltInformationDesktop,
  BeltInformationMobile,
  InfoBlock,
  Text,
  Title,
  GraphWrapper,
  InfoBox,
  TitleSmall,
  Info,
  FlexWrap,
  LoadWrap,
  TimeBox,
} from "./styled";

const GraphBox = (props: { tournamentDetails: any }) => {
  const { tournamentPairList } = useTournament();
  const { tournamentDetails } = props;
  const [timeLeft, setTimeLeft] = useState("");
  const [activeTime, setActiveTime] = useState("1s");
  const [chartType, setChartType] = useState("Area");
  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;
  const { rank, tournamentRanking } = useSocket();
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const { setAllCoins, selectedCoin, selectedCoinValue } = useContext(SocketContext);
  const { totalMoneyString } = useWalletData();
  const tradingPairsList = tournamentPairList;
  const userRanking = tournamentRanking.find(([user]) => user === username);
  const totalUSDT = tournamentType === "Margin" ? (userRanking ? parseFloat(userRanking[1]) : 0) : totalMoneyString;
  const initialMoney = tournamentDetails?.data?.attributes?.amount_start || 1;
  const percentageChange = ((totalUSDT - initialMoney) / initialMoney) * 100;
  const parseTotalMoney = formatCurrency(totalUSDT);
  const parsePercentageChange = percentageChange.toFixed(2);

  let price = selectedCoinValue.last
  let currency24hData = selectedCoinValue;

  const calculateTimeLeft = () => {
    const now = moment().utc();
    const end = moment.utc(tournamentDetails?.data?.attributes?.end_at);
    const duration = moment.duration(end.diff(now));
    const totalDays = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    setTimeLeft(`${totalDays}d ${hours}h ${minutes}m ${seconds}s`);
  };

  useEffect(()=>{
    setAllCoins(tradingPairsList)
  })

  useEffect(() => {
    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(intervalId);
  });

  return (
    <>
      <Box>
        <Wrap>
          <FlexWrap>
            <BrowserView>
              <BeltInformationDesktop>
                <Currency>{selectedCoin && (selectedCoin?.name || selectedCoin)}</Currency>
                <Amount>
                  {price && !isNaN(price) ?
                    <p>
                      {formatPrice(selectedCoin?.name, price)}
                    </p>
                  : <LoadWrap/> }
                </Amount>

                <InfoBlock>
                  <Title>24h Change</Title>
                  <Text className={currency24hData.change >= 0 ? "up_state" : "down_state"}>
                    {currency24hData.change && !isNaN(currency24hData.change) ?
                      <p>
                       {formatPrice(selectedCoin?.name, currency24hData.change)}
                       {currency24hData.percentage >= 0
                         ? ` +${currency24hData?.percentage?.toFixed(2)}%`
                         : ` ${currency24hData?.percentage?.toFixed(2)}%`}
                      </p> : <span>...</span>
                    }
                  </Text>
                </InfoBlock>
                <InfoBlock>
                  <Title>24h High</Title>
                  <Text>
                    {currency24hData.high24h && !isNaN(currency24hData.high24h) ?
                      <p>
                        {formatPrice(selectedCoin?.name, currency24hData.high24h)}
                      </p>
                    : <p>...</p> }
                  </Text>
                </InfoBlock>
                <InfoBlock>
                  <Title>24h Low</Title>
                  <Text>
                    {currency24hData.low24h && !isNaN(currency24hData.low24h) ?
                      <p>
                        {formatPrice(selectedCoin?.name, currency24hData.low24h)}
                      </p>
                    : <p>...</p> }
                  </Text>
                </InfoBlock>
              </BeltInformationDesktop>
            </BrowserView>
            <MobileView>
              <BeltInformationMobile>
                <>
                  <InfoBox>
                    <TitleSmall>Total asset value</TitleSmall>
                    <Info>
                      {parseTotalMoney && <>{parseTotalMoney} USDT </>}
                      <span
                        className={`${
                          percentageChange >= 0 ? "up_state" : "down_state"
                        }`}>
                        {percentageChange >= 0
                          ? parsePercentageChange && (
                              <>+{parsePercentageChange}%</>
                            )
                          : parsePercentageChange && (
                              <>{parsePercentageChange}%</>
                            )}
                      </span>
                    </Info>
                  </InfoBox>
                </>
                <>
                  <InfoBox>
                    <TitleSmall>Time Left</TitleSmall>
                    <TimeBox>
                      <TournamentCountdown date={tournamentDetails?.data?.attributes?.end_at} />
                    </TimeBox>
                  </InfoBox>
                </>
                <>
                  <InfoBox>
                    <TitleSmall>Rank</TitleSmall>
                    <Info>
                      <span
                        className={`${
                          percentageChange >= 0 ? "up_state" : "down_state"
                        }`}>
                        {rank}
                      </span>
                    </Info>
                  </InfoBox>
                </>
              </BeltInformationMobile>
            </MobileView>

            <WrapAsset/>
          </FlexWrap>
          <Currency className="hideDesktop">{selectedCoin && (selectedCoin?.name || selectedCoin)}</Currency>
          <GraphFilter
            activeTime={activeTime}
            setActiveTime={setActiveTime}
            chartType={chartType}
            setChartType={setChartType}
          />
          <GraphWrapper className={tournamentType}>
            <TradingView
              activeTime={activeTime}
              chartType={chartType}
            />
          </GraphWrapper>
        </Wrap>
      </Box>
    </>
  );
};

export default GraphBox;
