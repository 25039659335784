import styled from 'styles/styled';

export const TournamentRank = styled.ul`
  padding: 0 17px 0 20px;
  max-height: calc(100% - 24px);
  overflow-y: scroll;
  margin-right: 5px;
  @media (max-width: 1020px) {
    padding: 4px 20px 0px 15px;
    max-height: 100%;
  }
`;

export const TournamentList = styled.li`
  list-style-type: decimal;
  font-size: 12px;
  color: #EFEFEF;
  font-weight: 700;
  margin-bottom: 15px;
  vertical-align: top;
  margin-left: 15px;
  &:first-child {
    margin-top: 4px;
  }
  .AmountDetails {
    margin-top: 2px;
  }
  @media (max-width: 1020px) {
    margin-bottom: 10px;
  }
  @media (max-width: 720px) {
    font-size: 10px;
  }
`;

export const WrappList = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
`;

export const TournamentBelt = styled.div`
  display: flex;
  justify-content: space-between;
  &.is_me {
    background-color: #FFD700;
    border-radius: 3px;
    color: #000;
    padding: 4px;
    margin-top: -4px;
  }
`;

export const NickName = styled.h6`
  font-size: 12px;
  font-weight: 700;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 720px) {
    font-size: 10px;
  }
`;

export const Percent = styled.h6`
  font-size: 11px;
  font-weight: 500;
  @media (max-width: 720px) {
    font-size: 10px;
  }
`;

export const Amount = styled.h6`
  font-size: 11px;
  color: #52D381;
  font-weight: 700;
  @media (max-width: 720px) {
    font-size: 10px;
  }
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
`;
