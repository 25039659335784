import React, { useEffect, useState, useContext, useRef } from 'react';

import { formatCurrency } from 'utils';
import { useWalletData } from 'context/WalletDataContext';
import { useTradeUpdate } from 'context/TradeUpdateContext';
import { v4 as uuidv4 } from 'uuid';
import { makeMargin } from 'api/socketService';
import { ReactComponent as info_svg } from 'assets/Icon/icon_info.svg';
import SocketContext from 'utils/hooks/useSocket';
import { ButtonFilled } from 'components/ui/Button';
import SetTPSL from 'components/ui/SetTPSL';
import IconSvg from 'components/ui/IconSvg';


import {
  Row,
  RowBelt,
  Text,
  Amount,
  InputRow,
  Info,
  InputRange,
  InputText,
  LineRange,
  Percentages,
  LoadWrap,
  LoadButton,
  CheckMark,
  FlexRow,
  ButtonWrap,
  WrapCol,
  MoreInfoBox,
} from './styled';

const OpenLong = (props) => {
  const [rangeval, setRangeval] = useState(0);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [coinInputValue, setCoinInputValue] = useState('');
  const [usdtInputValue, setUsdtInputValue] = useState('');
  const [inputFocused, setInputFocused] = useState(null);
  const [isTrading, setIsTrading] = useState(false);
  const { tournamentDetails } = props;
  const { walletData, update } = useWalletData();
  const { triggerTradeUpdate } = useTradeUpdate();
  const usdtWallet = walletData.find(wallet => wallet.ticker.short_name === 'USDT');
  const availableUsdt = usdtWallet ? usdtWallet.amount : 0;
  const {selectedCoinValue, selectedCoin } = useContext(SocketContext);
  const lastClickTimestamp = useRef(0);
  const [isOpenTpSl, setIsOpenTpSl] = useState(false);
  const [isLimitActive, setIsLimitActive] = useState(false);
  const [limitPrice, setLimitPrice] = useState('');
  const [selectedLeverage, setSelectedLeverage] = useState(1); // Default leverage
  const [takeProfit, setTakeProfit] = useState('');
  const [stopLoss, setStopLoss] = useState('');
  const [currentTradeId] = useState(null);
  const [isActiveInfoType, setIsActiveInfoType] = useState(false);

  let shortNameSelectedCoin = selectedCoin?.name?.split('/')[0]
  let price = selectedCoinValue.last;
  let precision = selectedCoin?.base?.data?.attributes?.precision ? selectedCoin?.base?.data?.attributes?.precision : selectedCoin?.precision
  if(precision > 8){precision = 8;}
  const disabledBTN = availableUsdt?.toFixed(precision) <= 0;
  let disabledBTNValue = availableUsdt?.toFixed(precision) <= 0; //usdtInputValue <= 0;
  const leverageOptions = tournamentDetails?.data?.attributes?.leverage || [];

  useEffect(() => {
    if (!inputFocused) {

      const newUsdtAmount = (rangeval / 100) * availableUsdt;
      const newBtcAmount = newUsdtAmount / price;
      setUsdtAmount(newUsdtAmount);
      setCoinInputValue(newBtcAmount.toFixed(precision));
      setUsdtInputValue(newUsdtAmount.toFixed(2));
    }
  }, [rangeval, price, inputFocused]);

  const handleBtcInputFocus = () => {
    setCoinInputValue(''); // Clear BTC input on focus
    setInputFocused(shortNameSelectedCoin);
  };

  const handleUsdtInputFocus = () => {
    setUsdtInputValue(''); // Clear USDT input on focus
    setInputFocused('USDT');
  };

  const handleBtcInputBlur = () => {
    setInputFocused(null);
    // If BTC input is not empty, keep the formatted value
    if (coinInputValue === '') {
      setUsdtAmount(0); // Also reset the USDT amount
    }
  };

  const handleUsdtInputBlur = () => {
    setInputFocused(null);
    // If USDT input is not empty, keep the formatted value
    if (usdtInputValue === '') {
      setUsdtAmount(0); // Ensure the numeric state is zero when input is empty
    }
  };

  const handleBtcInputChange = (value) => {
    const numericValue = value === '' ? 0 : Number(value);
    setCoinInputValue(value); // Update display value

    // Calculate USDT amount based on BTC input
    let newUsdtAmount = numericValue * price;
    // If the new USDT amount exceeds the maximum, cap it
    if (newUsdtAmount > availableUsdt) {
      newUsdtAmount = availableUsdt;
      setCoinInputValue((newUsdtAmount / price).toFixed(precision)); // Update the BTC input to capped value
    }
    setUsdtAmount(newUsdtAmount);
    setUsdtInputValue(newUsdtAmount.toFixed(2)); // Update USDT input display

    // Update slider value
    const sliderValue = (newUsdtAmount / availableUsdt) * 100;
    setRangeval(sliderValue <= 100 ? sliderValue : 100); // Cap the slider at 100%
  };

  const handleUsdtInputChange = (value) => {
    const numericValue = value === '' ? 0 : Number(value);
    setUsdtInputValue(value); // Update display value
    let newUsdtAmount = numericValue; // Update numeric amount for calculations

    // If the new USDT amount exceeds the maximum, cap it
    if (newUsdtAmount > availableUsdt) {
      newUsdtAmount = availableUsdt;
      setUsdtInputValue(newUsdtAmount.toFixed(2)); // Update the USDT input to capped value
    }
    setUsdtAmount(newUsdtAmount);

    // Update slider value
    const sliderValue = (newUsdtAmount / availableUsdt) * 100;
    setRangeval(sliderValue <= 100 ? sliderValue : 100); // Cap the slider at 100%
  };

  const resetValues = () => {
    setRangeval(0);
    setUsdtAmount(0);
    setSelectedLeverage(1);
    setLimitPrice('');
    setTakeProfit('');
    setStopLoss('');
  };

  const handleMarketClick = () => {
    setIsLimitActive(false);
  };

  const handleLimitClick = () => {
    setIsLimitActive(true);
  };

  const handleLimitPriceChange = (e) => {
    setLimitPrice(e.target.value);
  };

  const handleLeverageChange = (e) => {
    setSelectedLeverage(Number(e.target.value));
  };

  const handleAccept = () => {
    setIsOpenTpSl(false);
  };

  const handleCancel = () => {
    setIsOpenTpSl(false);
  };

  const handleMarginTrade = (transactionType) => {
    if (isTrading) {
      return;
    }
    const now = Date.now();
    setIsTrading(true);

    if (now - lastClickTimestamp.current < 1000) {
      return;
    }

    lastClickTimestamp.current = now;

    const tradePayload = {
      tradingPair: selectedCoin?.name,
      transactionType,
      leverage: selectedLeverage,
      usedFunds: Number(usdtAmount.toFixed(2)),
      token: uuidv4(),
      ...(isLimitActive && { limit: Number(limitPrice) }), // Include limit if active
      ...(takeProfit && { takeProfit: Number(takeProfit) }), // Include take profit if set
      ...(stopLoss && { stopLoss: Number(stopLoss) }), // Include stop loss if set
    };

    makeMargin(tradePayload, (response) => {
      if(response.error){
        console.error(response.error);
        alert(response.error);
      }
      update();
      triggerTradeUpdate();
      resetValues();
      setIsTrading(false);
    });
  };

  const handleClickInfo = event => {
    setIsActiveInfoType(!isActiveInfoType);
  };

  return (
    <>
      {isOpenTpSl &&
        <SetTPSL
          takeProfit={takeProfit}
          setTakeProfit={setTakeProfit}
          stopLoss={stopLoss}
          setStopLoss={setStopLoss}
          onAccept={handleAccept}
          onCancel={handleCancel}
          tradeId={currentTradeId}
        />
      }
      {!isOpenTpSl &&
        <>
        <Info>
          <Text className={isActiveInfoType ? 'isActiveInfoType' : ''}>
            Available funds<span> {formatCurrency(availableUsdt)} USDT</span>
            <div onClick={handleClickInfo} className="info_icon">
              <IconSvg Icon={info_svg} />
            </div>
            <MoreInfoBox onClick={handleClickInfo} className={isActiveInfoType ? 'isActiveInfoType' : ''}>
              <p><b>0.1%</b> transaction fee and <b>0.1%</b> spread is added to each transaction</p>
            </MoreInfoBox>
          </Text>
        </Info>

        <Row className="RowRadio">
          <label>
            <input
              className="InputRadio"
              type="radio"
              name="market"
              value={1}
              checked={selectedLeverage === 1}
              onChange={handleLeverageChange}
            />
            <CheckMark className={`checkmark ${selectedLeverage === 1 ? 'isActive' : ''}`}>X1</CheckMark>
          </label>

          <label>
            <input
              className="InputRadio"
              type="radio"
              name="market"
              value={0}
              disabled
            />
            <CheckMark className="checkmark">Leverage</CheckMark>
          </label>

          {leverageOptions.map((leverage, index) => (
            <label key={index}>
              <input
                className="InputRadio"
                type="radio"
                name="market"
                value={leverage.replace('x', '')}
                checked={selectedLeverage === Number(leverage.replace('x', ''))}
                onChange={handleLeverageChange}
              />
              <CheckMark className={`checkmark ${selectedLeverage === Number(leverage.replace('x', '')) ? 'isActive' : ''}`}>X{leverage.replace('x', '')}</CheckMark>
            </label>
          ))}
        </Row>
        <RowBelt>
          <FlexRow>
            <ButtonFilled
              className={`ButtonSmall btnMarket ${!isLimitActive ? 'isActive' : ''}`}
              type="button"
              onClick={handleMarketClick}
            >
              Market
            </ButtonFilled>
            <ButtonFilled
              className={`ButtonSmall btnLimit ${isLimitActive ? 'isActive' : ''}`}
              type="button"
              onClick={handleLimitClick}
            >
              Limit
            </ButtonFilled>
          </FlexRow>
          <ButtonFilled
            className="ButtonSmall"
            type="button"
            onClick={() => setIsOpenTpSl(!isOpenTpSl)}
          >
            TP/SL
          </ButtonFilled>
        </RowBelt>

        {!isLimitActive &&
          <Row className="DarkInfo">
            <Text>Market price</Text>
              <Amount>{price} USDT</Amount>
          </Row>
        }

        {isLimitActive &&
          <Row className="RowInput">
            <Text className="TextSpecial">Price</Text>
            <InputText
              type="number"
              value={limitPrice}
              onChange={handleLimitPriceChange}
            />
            <Amount className="AmountSpecial">USDT</Amount>
          </Row>
        }

        <InputRow className="lineRangeRow">
          <LineRange style={{ width: `${rangeval}%` }}>
            <Percentages className={!usdtAmount ? "" : "active"}>
              {/* Guard against NaN by checking if rangeval is a number */}
              <span>{!isNaN(rangeval) ? `${rangeval.toFixed(0)}%` : '0%'}</span>
            </Percentages>
          </LineRange>
          <InputRange
            disabled={disabledBTN}
            type="range"
            value={rangeval}
            min="0"
            max="100"
            onChange={(event) => {
              const value = Number(event.target.value);
              setRangeval(value); // Update the slider value

              // Perform calculations based on the slider value
              const newUsdtAmount = (value / 100) * availableUsdt;
              setUsdtAmount(newUsdtAmount); // Update numeric USDT amount
              setUsdtInputValue(newUsdtAmount.toFixed(2)); // Update USDT input display

              const newBtcAmount = newUsdtAmount / price;
              setCoinInputValue(newBtcAmount.toFixed(precision)); // Update BTC input display
            }}
          />
        </InputRow>

        <Row className="RowInput">
          <Text className="TextSpecial">Amount</Text>
          <InputText
            type="number"
            pattern="\d*(\.\d+)?"
            step="0.0001"
            min="0"
            value={coinInputValue}
            onFocus={handleBtcInputFocus}
            onBlur={handleBtcInputBlur}
            onChange={(e) => handleBtcInputChange(e.target.value)}
          />
          <Amount className="AmountSpecial">{shortNameSelectedCoin}</Amount>
        </Row>
        <Row className="RowInput">
          <Text className="TextSpecial">Sum</Text>
          <InputText
            type="number"
            pattern="\d*(\.\d+)?"
            step="0.01"
            min="0"
            value={usdtInputValue}
            onFocus={handleUsdtInputFocus}
            onBlur={handleUsdtInputBlur}
            onChange={(e) => handleUsdtInputChange(e.target.value)}
          />
          <Amount className="AmountSpecial">USDT</Amount>
        </Row>

        <ButtonWrap>
          <WrapCol>
            {isTrading ?
              <LoadButton>
                <ButtonFilled className="buttonLoad" disabled={true} type="button"><LoadWrap></LoadWrap> Loading...</ButtonFilled>
              </LoadButton>
            :
              <ButtonFilled
                type="button"
                onClick={() => handleMarginTrade('long')}
                disabled={disabledBTNValue ? true : false}
              >
                Open Long
              </ButtonFilled>
            }
          </WrapCol>
          <WrapCol className="hideDesktop">
            {isTrading ?
              <LoadButton>
                <ButtonFilled className="buttonLoad" disabled={true} type="button"><LoadWrap></LoadWrap> Loading...</ButtonFilled>
              </LoadButton>
            :
              <ButtonFilled
                type="button"
                onClick={() => handleMarginTrade('short')}
                disabled={disabledBTNValue ? true : false}
              >
                Open Short
              </ButtonFilled>
            }
          </WrapCol>
        </ButtonWrap>
        </>
      }
    </>
  );
};

export default OpenLong;
