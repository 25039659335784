import React, { useState } from 'react';
import { ReactComponent as IconGraphSVG } from 'assets/icons/icon_gr.svg';
import IconSvg from 'components/ui/IconSvg';

import {
  Row,
  Button,
  IconWrap,
  IconBox,
  Text,
  MoreInfoBox,
  LineButton,
} from './styled';

const GraphFilter = ({ activeTime, setActiveTime, chartType, setChartType }) => {
  const [isActiveChartType, setIsActiveChartType] = useState(false);

  const handleClickChartType = event => {
    setIsActiveChartType(!isActiveChartType);
  };

  const handleTimeClick = (time) => {
    setActiveTime(time);
  };

  const handleChartTypeClick = (type) => {
    setChartType(type);
  };

  return (
    <Row>
      <Text>Time</Text>
      <Button onClick={() => handleTimeClick('1s')} className={activeTime === '1s' ? 'is_active' : ''}>1s</Button>
      <Button onClick={() => handleTimeClick('1m')} className={activeTime === '1m' ? 'is_active' : ''}>1m</Button>
      <Button onClick={() => handleTimeClick('5m')} className={activeTime === '5m' ? 'is_active' : ''}>5m</Button>
      <Button onClick={() => handleTimeClick('15m')} className={activeTime === '15m' ? 'is_active' : ''}>15m</Button>
      <Button onClick={() => handleTimeClick('30m')} className={activeTime === '30m' ? 'is_active' : ''}>30m</Button>
      <Button onClick={() => handleTimeClick('1h')} className={activeTime === '1h' ? 'is_active' : ''}>1h</Button>

      <IconBox>
        <IconWrap onClick={handleClickChartType}>
          <IconSvg Icon={IconGraphSVG} />
          <MoreInfoBox className={isActiveChartType ? 'isActiveChartType' : ''}>
            <p>Choose chart type:</p>
            <LineButton>
              <LineButton>
                <Button onClick={() => handleChartTypeClick('Candles')} className={chartType === 'Candles' ? 'is_active' : ''}>Candles</Button>
                <Button onClick={() => handleChartTypeClick('Line')} className={chartType === 'Line' ? 'is_active' : ''}>Line</Button>
                <Button onClick={() => handleChartTypeClick('Bars')} className={chartType === 'Bars' ? 'is_active' : ''}>Bars</Button>
                <Button onClick={() => handleChartTypeClick('Area')} className={chartType === 'Area' ? 'is_active' : ''}>Area</Button>
              </LineButton>
            </LineButton>
          </MoreInfoBox>
        </IconWrap>
      </IconBox>
    </Row>
  );
};

export default GraphFilter;
