import styled from 'styles/styled';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';

export const Wrapper = styled.div`

`;

export const BtnBack = styled(StyledLink)`
  border: 1px solid #EFEFEF;
  color: #EFEFEF;
  width: 100%;
  display: block;
  border-radius: 6px;
  font-weight: 700;
  height: 30px;
  font-size: 12px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 2px;
  }
  @media (min-width: 1020px) {
    display: none;
  }
`;

export const ColLeft = styled.div`
  width: calc(100% - 290px);
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export const ColRight = styled.div`
  width: 282px;
  @media (max-width: 1020px) {
    display: none;
  }
`;

export const Box = styled.div`
  background-color: #15191E;
  width: 100%;
  border-radius: 10px;
  min-height: 600px;
  margin-bottom: 8px;
  height: 100vh;
  max-height: calc(100vh - 65px - 8px);
  max-height: calc(100svh - 65px - 8px);
  @media (max-width: 1020px) {
    max-height: calc(100vh - 90px - 8px);
    max-height: calc(100svh - 90px - 8px);
  }
  @media (min-height: 1000px) {
    max-height: calc(100vh - 282px);
    max-height: calc(100svh - 282px);
  }
  @media (min-height: 1600px) {
    max-height: calc(70vh - 50px - 8px);
    max-height: calc(70svh - 50px - 8px);
  }
  @media (max-width: 720px) {
    min-height: 500px;
  }
`;

export const RowTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
