import React from 'react';
import { useTournament } from 'context/TournamentContext';

import TradeHistory from 'components/game/TradeHistory';
import TradeHistoryMargin from 'components/game/TradeHistoryMargin';
import UserState from 'components/StatusFlow/UserState';
import Footer from 'components/ui/Footer';

import {
  RowBottom,
  ColLeft,
  Box,
  ColRight,
} from './styled';

const BottomSection = (props: { tournamentDetails: any; }) => {
  const { tournamentDetails } = useTournament();
  const tournamentType = tournamentDetails?.data?.attributes?.trade_type;
  return (
    <RowBottom>
      <ColLeft>
        <Box>
          {(tournamentType === 'Margin') ? (
            <TradeHistoryMargin tournamentDetails={tournamentDetails} />
          ) :
            <TradeHistory tournamentDetails={tournamentDetails} />
          }
        </Box>
      </ColLeft>
      <ColRight className="asideBox">
        <Box className="centerBox">
          <UserState tournamentDetails={tournamentDetails} />
        </Box>
        <Box className="centerBox">
          <Footer />
        </Box>
      </ColRight>
    </RowBottom>
  );
}
export default BottomSection;
