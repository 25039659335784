import styled from 'styles/styled';
import imgAvatar from '../../../assets/icons/IconBull.svg';

export const Row = styled.div`
  display: flex;
  padding: 15px 0;
`;

export const RowBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  button {
    min-width: inherit;
    min-height: 40px;
    max-width: 460px;
    width: 220px;
    border: 1px solid #52D381;
    color: #52D381;
    background-color: transparent;
    &:hover {
      background-color: #52D381;
      color: #212121;
    }
  }
`;

export const IconAvatar = styled.div`
  width: 34px;
  height: 34px;
  background-color: #2E2E2E;
  border-radius: 100%;
  margin-right: 10px;
  background-image: url(${imgAvatar});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  &.notLogin {
    background-image: none;
    opacity: 0.3;
  }
`;

export const Text = styled.h3`
  font-size: 14px;
  margin-bottom: 2px;
  color: #EFEFEF;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
  &.nickName {
    color: #000;
    background-color: #FFD700;
    border-radius: 2px;
    padding: 0 3px;
  }
`;

export const TextLine = styled.p`
  color: #7b7b7b;
  text-align: center;
    display: block;
    width: 100%;
    font-size: 14px;
    margin: 10px 0;
    position: relative;
    z-index: 5;
    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #3d3d3d;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: -2;
    }
    &:before {
      content: " ";
      position: absolute;
      width: 40px;
      height: 100%;
      background-color: #15191E;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: -1;
    }
`;
