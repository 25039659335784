import React, { useEffect, useState, useContext } from 'react';
import { requestTradingData, closeMargin, cancelMargin, editMargin } from 'api/socketService';
import SocketContext, { useSocket } from 'utils/hooks/useSocket';
import { ButtonFilled } from 'components/ui/Button';
import { useWalletData } from 'context/WalletDataContext';
import SetTPSL from 'components/ui/SetTPSL';
import { useTradeUpdate } from 'context/TradeUpdateContext';
import {
  Wrap,
  Tab,
  Button,
  Box,
  BoxRow,
  BoxLeft,
  BoxRight,
  BoxInfo,
  Icon,
  WrapIcon,
  CurrencyInfo,
  AmountInfo,
  Percentages,
  RowInfo,
  InfoMargin,
  Leverage,
  BoxContent,
  Col,
  ColWrap,
  ColRow,
  Text,
  SmallBox,
  SmallText,
  EditBoxWrap,
  BoxButton,
  WrapListTransaction,
  RowTop,
  RowContent,
  BoxProfit,
  Amount,
  ListPerformance,
  RowText,
  WrapTPSL,
  LoadWrap,
} from './styled';

type Trade = {
  id: number;
  trading_pair: string;
  amount: number;
  price: number;
  market_value: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  transaction_type: string;
  leverage: number;
  status: string;
  is_settled: boolean;
  used_funds: number;
  liquidation_price: number;
  description: string;
  limit?: number;
  take_profit?: number;
  stop_loss?: number;
  profit?: number;
  profit_percentage?: number;
  break_even_price?: number;
  fee?: number;
  coin_amount?: number;
};

const TradeHistoryMarginMobile = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const handleTab3 = () => { setActiveTab("tab3"); };
  const [isOpenTransaction, setOpenTransaction] = useState(true);
  const [tradingData, setTradingData] = useState<Trade[]>([]);
  const { tournamentDetails } = useSocket();
  const { walletData, update } = useWalletData();
  const { allCoinsValues, tradingUpdates } = useContext(SocketContext);
  const { triggerTradeUpdate } = useTradeUpdate();
  const [isOpenTpSl, setIsOpenTpSl] = useState(false);
  const [takeProfit, setTakeProfit] = useState(undefined);
  const [stopLoss, setStopLoss] = useState(undefined);
  const [currentTradeId, setCurrentTradeId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  const handleAccept = () => {
    if (currentTradeId !== null) {
      const editPayload = {
        stopLoss: stopLoss || undefined,
        takeProfit: takeProfit || undefined,
        id: currentTradeId,
      };

      editMargin(editPayload, (response) => {
        if (response.success) {
          setIsOpenTpSl(false);
          setTradingData(prevData =>
            prevData.map(trade =>
              trade.id === currentTradeId
                ? { ...trade, take_profit: Number(takeProfit), stop_loss: Number(stopLoss) }
                : trade
            )
          );
        } else {
          // Handle error
          console.error('Error editing margin:', response.error);
        }
      });
    }
  };

  const handleCancel = () => {
    setIsOpenTpSl(false);
  };

  const updateTrading = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      setTradingData(data);
    } else {
      console.error('Error fetching trading data:');
    }
  };

  const fetchTradingData = () => {
    setLoading(true);
    const tournamentId = tournamentDetails?.data?.id;
    const tradeType = tournamentDetails?.data?.attributes?.trade_type;
    if (tournamentId) {
      requestTradingData(tournamentId, tradeType, updateTrading);
      setLoading(false);
    }
  };

  const [currentWalletData, updateCurrentWalletData] = useState(null)

  if(walletData?.length){
    if(currentWalletData?.length){
      walletData.forEach((element, i) => {
        if(element?.amount !== currentWalletData[i]?.amount){
          updateCurrentWalletData(walletData)
        }
      });
    }else{
      updateCurrentWalletData(walletData)
    }
  }

  useEffect(()=>{
    fetchTradingData();
  },[currentWalletData ])

  const filterTradesByStatus = (statuses, isSettled = null) => {
    return tradingData.filter(trade => {
      if (isSettled !== null) {
        return statuses.includes(trade.status) && trade.is_settled === isSettled;
      }
      return statuses.includes(trade.status);
    });
  };

  const calculateProfit = (currentPrice, trade) => {
    const multiplier = trade.transaction_type === 'long' ? 1 : -1;
    const profit = (((currentPrice - trade.break_even_price) / trade.break_even_price) * (trade.used_funds - trade.fee) * trade.leverage * multiplier) - trade.fee;
    const profitPercent = (profit / (trade.used_funds)) * 100;
    return { profit, profitPercent };
  };

  // Update tradingData whenever tradingUpdates changes
  useEffect(() => {
    if (tradingUpdates && Array.isArray(tradingUpdates)) {
      setTradingData(tradingUpdates);
      update(); // Update available funds
    }
  }, [tradingUpdates]);

  return (
    <Wrap>
      <Tab>
        <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Open</Button>
        <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Pending</Button>
        <Button className={activeTab === "tab3" ? "active" : ""} onClick={handleTab3}>History</Button>
      </Tab>

      {isOpenTpSl &&
        <WrapTPSL>
          <SetTPSL
            takeProfit={takeProfit}
            setTakeProfit={setTakeProfit}
            stopLoss={stopLoss}
            setStopLoss={setStopLoss}
            onAccept={handleAccept}
            onCancel={handleCancel}
            tradeId={currentTradeId}
          />
        </WrapTPSL>
      }

      {!isOpenTpSl &&
        <>
          {activeTab === "tab1" ?
            <WrapListTransaction>
              <>
                {loading ? (
                  <LoadWrap />
                ) : (
                  <>
                    {filterTradesByStatus(["filled"], false).map(trade => {
                      const tradingPairKey = trade.trading_pair.split('/')[0].toLowerCase();
                      const currentPrice = allCoinsValues?.[tradingPairKey]?.last || 0;
                      const { profit, profitPercent } = calculateProfit(currentPrice, trade);
                      return (
                        <Box key={trade.id}>
                          <BoxRow>
                            <BoxLeft>
                              <WrapIcon>
                                <Icon $src={`../icons/tradingPair/use/${trade.trading_pair?.split('/USDT')[0]}.svg`}/>
                              </WrapIcon>
                              <BoxInfo>
                                <RowInfo>
                                  <InfoMargin className={trade.transaction_type === "long" ? "up_color" : "down_color"}>
                                    {trade.transaction_type === "long" ? "Long" : "Short"}
                                  </InfoMargin>
                                  <Leverage>X{trade.leverage}</Leverage>
                                </RowInfo>
                                <CurrencyInfo>{trade.trading_pair}</CurrencyInfo>
                              </BoxInfo>
                            </BoxLeft>
                            <BoxRight className={profit >= 0 ? "up_color" : "down_color"}>
                              <span>Profit</span>
                              <AmountInfo>{profit.toFixed(2)} USDT</AmountInfo>
                              <Percentages>({profitPercent.toFixed(2)}%)</Percentages>
                            </BoxRight>
                          </BoxRow>

                          <BoxContent>
                            {isOpenTransaction &&
                              <>
                                <ColWrap>
                                  <Col className="colLeft">
                                    <ColRow>
                                      <Text><span>Used Funds</span>{trade.used_funds} USDT</Text>
                                    </ColRow>
                                    <ColRow>
                                      <Text><span>Amount</span>{trade.coin_amount} {trade.trading_pair.replace('/USDT', '')}</Text>
                                    </ColRow>
                                    <ColRow>
                                      <Text><span>Price</span>{trade.price.toFixed(2)} USDT</Text>
                                    </ColRow>
                                    <ColRow>
                                      <Text><span>Liquidation Price</span>{trade.liquidation_price.toFixed(2)} USDT</Text>
                                    </ColRow>
                                  </Col>
                                  <Col>
                                    <SmallBox onClick={() => {
                                      setIsOpenTpSl(!isOpenTpSl);
                                      setTakeProfit(trade.take_profit ? Number(trade.take_profit) : undefined);
                                      setStopLoss(trade.stop_loss ? Number(trade.stop_loss) : undefined);
                                      setCurrentTradeId(trade.id);
                                    }}>
                                      <SmallText>Tap to edit</SmallText>
                                      <Text className="up_color"><span>TP</span>{trade.take_profit ?? 'Not set'}</Text>
                                      <Text className="down_color"><span>SL</span>{trade.stop_loss ?? 'Not set'}</Text>
                                    </SmallBox>
                                  </Col>
                                </ColWrap>
                                <ButtonFilled
                                  className="button"
                                  type="button"
                                  onClick={() => setOpenTransaction(!isOpenTransaction)}
                                >
                                  Close position
                                </ButtonFilled>
                              </>
                            }

                            {!isOpenTransaction &&
                              <EditBoxWrap>
                                <ColWrap className="editColWrap">
                                <p>Are you sure<br />you want to close this position?</p>
                                </ColWrap>
                                <BoxButton>
                                  <ButtonFilled
                                    className="button"
                                    type="button"
                                    onClick={() => {
                                      closeMargin(trade.id, (response) => {
                                        if(response.error){
                                          console.error(response.error);
                                          alert(response.error);
                                        }
                                        update();
                                        triggerTradeUpdate();
                                        setOpenTransaction(!isOpenTransaction);
                                      });
                                    }}
                                  >
                                    Yes
                                  </ButtonFilled>
                                  <ButtonFilled
                                    className="button"
                                    type="button"
                                    onClick={() => setOpenTransaction(!isOpenTransaction)}
                                  >
                                    No
                                  </ButtonFilled>
                                </BoxButton>
                              </EditBoxWrap>
                            }
                          </BoxContent>
                        </Box>
                      );
                    })}
                  </>
                )}
              </>
            </WrapListTransaction>
          : null}

          {activeTab === "tab2" ?
            <WrapListTransaction>
              {filterTradesByStatus(["pending"]).map(trade => (
                <Box key={trade.id}>
                  <BoxRow>
                    <BoxLeft>
                      <WrapIcon>
                        <Icon $src={`../icons/tradingPair/use/${trade.trading_pair?.split('/USDT')[0]}.svg`}/>
                      </WrapIcon>
                      <BoxInfo>
                        <RowInfo>
                          <InfoMargin className={trade.transaction_type === "long" ? "up_color" : "down_color"}>
                            {trade.transaction_type === "long" ? "Long" : "Short"}
                          </InfoMargin>
                          <Leverage>X{trade.leverage}</Leverage>
                        </RowInfo>
                        <CurrencyInfo>{trade.trading_pair}</CurrencyInfo>
                      </BoxInfo>
                    </BoxLeft>
                    <BoxRight>
                      <span>Limit</span>
                      <AmountInfo>{trade.limit} USDT</AmountInfo>
                    </BoxRight>
                  </BoxRow>

                  <BoxContent>
                    {isOpenTransaction &&
                      <>
                        <ColWrap>
                          <Col className="colLeft">
                            <ColRow>
                              <Text><span>Used Funds</span>{trade.used_funds} USDT</Text>
                            </ColRow>
                            <ColRow>
                              <Text><span>Amount</span>{trade.amount} {trade.trading_pair.replace('/USDT', '')}</Text>
                            </ColRow>
                          </Col>
                          <Col>
                            <SmallBox onClick={() => {
                              setIsOpenTpSl(!isOpenTpSl);
                              setTakeProfit(trade.take_profit ? String(trade.take_profit) : '');
                              setStopLoss(trade.stop_loss ? String(trade.stop_loss) : '');
                              setCurrentTradeId(trade.id);
                            }}>
                              <SmallText>Tap to edit</SmallText>
                              <Text className="up_color"><span>TP</span>{trade.take_profit ?? 'Not set'}</Text>
                              <Text className="down_color"><span>SL</span>{trade.stop_loss ?? 'Not set'}</Text>
                            </SmallBox>
                          </Col>
                        </ColWrap>
                        <ButtonFilled
                          className="button"
                          type="button"
                          onClick={() => setOpenTransaction(!isOpenTransaction)}
                        >
                          Cancel order
                        </ButtonFilled>
                      </>
                    }

                    {!isOpenTransaction &&
                      <EditBoxWrap>
                        <ColWrap className="editColWrap">
                        <p>Are you sure<br />you want to cancel this order?</p>
                        </ColWrap>
                        <BoxButton>
                          <ButtonFilled
                            className="button"
                            type="button"
                            onClick={() => {
                              cancelMargin(trade.id, (response) => {
                                if(response.error){
                                  console.error(response.error);
                                  alert(response.error);
                                }
                                update();
                                triggerTradeUpdate();
                                setOpenTransaction(!isOpenTransaction);
                              });
                            }}
                          >
                            Yes
                          </ButtonFilled>
                          <ButtonFilled
                            className="button"
                            type="button"
                            onClick={() => setOpenTransaction(!isOpenTransaction)}
                          >
                            No
                          </ButtonFilled>
                        </BoxButton>
                      </EditBoxWrap>
                    }
                  </BoxContent>
                </Box>
              ))}
            </WrapListTransaction>
          : null}

          {activeTab === "tab3" ?
            <ListPerformance>
              {filterTradesByStatus(["filled", "canceled"]).map(trade => {
                return (
                  <li key={trade.id}>
                    <div>
                      <RowTop>
                        <>
                          <span>{trade.trading_pair}</span>
                        </>
                        <>
                          <span>{new Date(trade.createdAt).toLocaleString()}</span>
                        </>
                      </RowTop>

                      <RowContent>
                        <Col className="col_left">
                          <RowText className={trade.transaction_type === "long" ? "up_color" : "down_color"}>
                            <span>Type</span>
                            <p>{trade.description}</p>
                          </RowText>
                          <RowText className="detail_color">
                            <span>Leverage</span>
                            <p>X{trade.leverage}</p>
                          </RowText>
                          <RowText>
                            <span>Used funds</span>
                            <p>{trade.used_funds} USDT</p>
                          </RowText>
                          <RowText>
                            <span>Amount</span>
                            <p>{trade.coin_amount} {trade.trading_pair.replace('/USDT', '')}</p>
                          </RowText>
                          <RowText>
                            <span>Price</span>
                            <p>{trade.price} USDT</p>
                          </RowText>
                        </Col>
                          <Col className="col_right">
                            <span className={trade.status === "filled" ? "up_color" : "detail_color"}>{trade.status.charAt(0).toUpperCase() + trade.status.slice(1)}</span>
                            {trade.status === "filled" && trade.is_settled && (
                            <BoxProfit>
                              <p>Profit</p>
                              <Amount className={trade.profit < 0 ? "down_color" : "up_color"}>{trade.profit.toFixed(2)} USDT</Amount>
                            </BoxProfit>
                            )}
                          </Col>
                      </RowContent>
                    </div>
                  </li>
                );
              })}
            </ListPerformance>
          : null }
        </>
      }
    </Wrap>
  );
};
export default TradeHistoryMarginMobile;
