import styled from 'styles/styled';

export const Title = styled.h4`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #EFEFEF;
  text-align: center;
  margin: 0 auto 15px auto;
  @media (max-width: 720px) {
    font-size: 16px;
    width: 280px;
    max-width: 90%;
    margin: 10px auto 15px auto;
  }
`;

export const SubTitle = styled.h4`
  font-size: 16px;
  color: #EFEFEF;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
  @media (max-width: 720px) {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 2px;
  }
  &.SecondSubTitle {
    font-weight: 300;
    margin-top: 30px;
    @media (max-width: 720px) {
      margin: 10px 0 20px 0;
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

export const Text = styled.h5`
  font-size: 16px;
  color: #EFEFEF;
  font-weight: 700;
  text-align: center;
  margin: 0 5px;
  width: 50%;
  text-align: right;
  &:nth-child(2) {
    color: #52D381;
    text-align: left;
  }
  @media (max-width: 720px) {
    font-size: 12px;
  }
  span {
    &.up_state {
      color: #52D381;
    }
    &.down_state {
      color: #FF4B4A;
    }
  }
`;

export const Position = styled.h2`
  font-size: 34px;
  font-weight: 500;
  color: #52D381;
  padding: 5px 0;
  @media (max-width: 720px) {
    font-size: 20px;
    padding: 2px 0;
  }
`;

export const SubText = styled.h6`
  font-size: 14px;
  color: #EFEFEF;
  font-weight: 300;
  @media (max-width: 720px) {
    font-size: 10px;
  }
`;

export const RowInformation = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
`;

export const ContentInformation = styled.div`
  margin-bottom: 20px;
  width: 100%;
  @media (max-width: 720px) {
    margin-bottom: 10px;
  }
`;

export const ContentInformationCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  h5 {
    text-align: center;
  }
`;

export const Wrap = styled.div`
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  position: relative;
  max-width: calc(100% - 150px);
  min-width: 240px;
  button {
    font-size: 15px;
    width: 100%;
    min-width: inherit;
    min-height: 40px;
    max-width: 460px;
    @media (max-width: 1020px) {
      max-width: 350px;
      margin: 0 auto;
    }
    @media (max-width: 720px) {
      font-size: 15px;
      width: 100%;
      min-width: inherit;
      min-height: 40px;
      max-width: 460px;
    }
  }
`;

export const WinWrap = styled.div`
  margin-bottom: 20px;
`;
