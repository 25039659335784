import styled from 'styles/styled';
import bgGraph from '../../../assets/PageDetail/bgGraph.png';

export const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${bgGraph});
  background-size: cover;
  background-position: center;
  .introPhoto {
    margin-bottom: 50px;
    animation: animeLight 3500ms infinite ease-in-out;
    @keyframes animeLight {
      0% { opacity: 1;}
      25% { opacity: 0.8;}
      50% { opacity: 0.9;}
      75% { opacity: 0.6;}
      100% { opacity: 1;}
    }
    svg {
      max-width: 600px;
      width: 400px;
      @media (max-width: 720px) {
        max-width: 70%;
      }
    }
  }
`;

export const LoadAnime = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 1px 1px 0 1px #52d382;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
