import styled from 'styles/styled';
import imgAvatar from '../../../assets/icons/IconBull.svg';

export const NickName = styled.div`
  font-size: 12px;
  font-weight: 700;
  padding-right: 10px;
  color: #EFEFEF;
  margin-left: 24px;
  @media (max-width: 1300px) {
    margin-left: 15px;
  }
  @media (max-width: 720px) {
    display: none;
  }
`;

export const AvatarIcon = styled.div`
  width: 30px;
  height: 30px;
  float: left;
  background-color: ${({ theme }) => theme.palette.darkMedium};
  border-radius: 100%;
  margin-right: 8px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${imgAvatar});
  @media (max-width: 1300px) {
    width: 26px;
    height: 26px;
  }
`;

export const AvatarName = styled.div`
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 1300px) {
    margin: 7px 0;
  }
`;
