import React, { useState, useEffect } from 'react';
import { useTournament } from 'context/TournamentContext';
import ResultsWrapper from 'components/layout/ResultsWrapper';
import TournamentRanking from 'components/Results/TournamentRanking';
import { WalletDataProvider } from 'context/WalletDataContext';
import { TradeUpdateProvider } from 'context/TradeUpdateContext';
import FullPageLoad from 'components/ui/FullPageLoad';

const Results = () => {
  const { tournamentDetails } = useTournament();
  const [isloadPage, setLoadPage] = useState(true);
    useEffect(() => {
        setTimeout(() => setLoadPage(false), 1000)
    }, [])
    if (isloadPage) {
        return <FullPageLoad/>;
    };

  return (
    <TradeUpdateProvider>
      <ResultsWrapper>
        <WalletDataProvider tournamentDetails={tournamentDetails}>
            <TournamentRanking />
        </WalletDataProvider>
      </ResultsWrapper>
    </TradeUpdateProvider>
  );
}
export default Results;
