import styled from 'styles/styled';

export const Wrap = styled.div`
  width: calc(100% - 50px);
  float: right;
  padding-right: 10px;
  @media (max-width: 720px) {
    width: calc(100% - 45px);
  }
`;

export const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 0;
`;

export const Button = styled.button`
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: #696969;
  appearance: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  &.active {
    color: #EFEFEF;
    &:after {
      content: " ";
      position: absolute;
      bottom: -2px;
      width: 20px;
      left: 0;
      height: 2px;
      border-radius: 3px;
      background-color: #EFEFEF;
    }
  }
`;

export const ListHistory = styled.ul`
  max-height: 220px;
  overflow-y: scroll;
  height: calc(100vh - 665px);
  @media (max-width: 720px) {
    height: 190px;
  }
  li {
    padding: 10px 0;
    margin-right: 10px;
    border-top: 1px solid #696969;
  }
`;

export const BeltTop = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Currency = styled.h4`
  color: #EFEFEF;
  line-height: 12px;
  font-size: 11px;
  font-weight: 400;
`;

export const DateInfo = styled.h5`
  font-size: 8px;
  line-height: 10px;
  font-weight: 400;
  color: #EFEFEF;
`;

export const Row = styled.div`
  margin-bottom: 1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Amount = styled.h4`
  color: #EFEFEF;
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  span {
    font-weight: 300;
    color: #696969;
    min-width: 35px;
    margin-right: 5px;
    float: left;
    text-align: right;
  }
`;

export const ValueInfo = styled.h4`
  color: #EFEFEF;
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  span {
    font-weight: 300;
    color: #696969;
    min-width: 35px;
    margin-right: 5px;
    float: left;
    text-align: right;
  }
`;

export const StatusInfo = styled.h4`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  &.down_state {
    color: #FF4B4A;
  }
  &.up_state {
    color: #52D381;
  }
`;

export const ListPerformance = styled.ul`
  max-height: 220px;
  overflow-y: scroll;
  height: calc(100vh - 665px);
  @media (max-width: 720px) {
    height: 190px;
  }
  li {
    margin-bottom: 7px;
    margin-right: 10px;
    background-color: #090B0E;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    justify-content: center;
  }
`;

export const RowText = styled.h5`
  color: #EFEFEF;
  line-height: 12px;
  font-size: 9px;
  font-weight: 400;
  &.down_state {
    color: #FF4B4A;
  }
  &.up_state {
    color: #52D381;
  }
  span {
    font-weight: 300;
    color: #696969;
    min-width: 45px;
    margin-right: 5px;
    float: left;
    text-align: right;
  }
`;

export const RowLargeText = styled.h4`
  color: #52D381;
  line-height: 12px;
  font-size: 11px;
  font-weight: 600;
  span {
    color: #EFEFEF;
    min-width: 45px;
    margin-right: 5px;
    float: left;
    text-align: right;
    font-size: 13px;
  }
`;

export const Load = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  font-weight: 700;
  font-size: 16px;
  margin-right: 45px;
  line-height: 18px;
  color: #EFEFEF;
`;

export const LoadWrap = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 1px 1px 0 1px #52d382;
  margin-bottom: 20px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
