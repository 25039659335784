import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTournament } from 'context/TournamentContext';

import EndPage from 'components/End/EndPage';
import ModalFull from 'components/ui/ModalFull';

import {
  LineText
} from './styled';

type TournamentCountdownProps = {
  date: string;
};

export const TournamentCountdown = ({ date }: TournamentCountdownProps) => {
  const [timeLeft, setTimeLeft] = useState('');
  const { tournamentDetails } = useTournament();

  const calculateTimeLeft = () => {
    const now = moment().utc();
    const end = moment.utc(date);
    const duration = moment.duration(end.diff(now));
    const totalDays = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    setTimeLeft(`${totalDays}d ${hours}h ${minutes}m ${seconds}s`);
  };

  useEffect(() => {
    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(intervalId);
  });

  if (timeLeft === '0d 0h 0m 0s') {
    window.location.reload();
  }

  if ('0d 0h 0m 0s' > timeLeft) {
    return <ModalFull><EndPage tournamentDetails={tournamentDetails} /></ModalFull>;
  }

  return <LineText className="timeText">{timeLeft}</LineText>;
};

export default TournamentCountdown;
