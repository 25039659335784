import React, { useContext } from "react";
import SocketContext from 'utils/hooks/useSocket';

import {
  Row,
  NameAssets,
  ContentAssets,
  IconWrap,
  Name,
  ListAssets,
  Icon,
  Status,
} from './styled';

const AllAssets = (props: { pairsList:any, onIconClick: any}) => {
  const { allCoinsValues } = useContext(SocketContext);
  const { pairsList, onIconClick} = props;
  const currency24hData = allCoinsValues;

  return (
    <>
      <Row>
        <NameAssets>Cryptocurrencies</NameAssets>
        <ContentAssets>
          <ul>
            {pairsList.map(({ id, attributes }) => (
              <ListAssets key={id} onClick={()=>onIconClick(attributes)}>
                <IconWrap>
                  <Icon className={attributes?.base?.data?.attributes.short_name} $src={`../icons/tradingPair/use/${attributes?.base?.data?.attributes.short_name}.svg`}/>
                </IconWrap>

                <Status className={currency24hData[attributes?.base?.data?.attributes?.short_name.toLowerCase()]?.change >= 0 ? "up_state" : "down_state"} />
                <Name>{`${attributes?.base?.data?.attributes.short_name}`}</Name>
              </ListAssets>
            ))}
          </ul>
        </ContentAssets>
      </Row>
    </>
  );
};

export default AllAssets;
